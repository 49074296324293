import React from "react";
const FormRenderer = ({ formData, formTitle, formDescription, onSubmit }) => {

  return (
    <div className="form-renderer-container">
      {formData.map((form) => (
        <div key={form.id} className="form-section">
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="nk-kycfm">
                <div
                  className="nk-kycfm-head"
                  style={{ background: "#f5f6fa" }}
                >
                  <div className="nk-kycfm-title">
                    <h5 className="title">{formTitle || "Untitled Form"}</h5>
                    <p className="sub-title">
                      {formDescription || "Untitled Form"}
                    </p>
                  </div>
                </div>
                <div className="nk-kycfm-content">
                  <div className="row g-gs">
                    {form.questions.map((question) => (
                      <div
                        key={question.id}
                        className="question-container col-sm-6 mt-3"
                      >
                        <div className="form-group question-text">
                          <label className="form-label">
                            {question.questionText}{" "}
                            {question.required && (
                              <sup className="text-danger">*</sup>
                            )}
                          </label>
                        </div>
                        {question.imageUrl && (
                          <img
                            src={question.imageUrl}
                            alt="Admin Uploaded"
                            className="question-image"
                            disabled
                          />
                        )}
                        {question.answerType === "input" && (
                          <>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  className={`form-control form-control-lg`}
                                  type="text"
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {question.answerType === "textarea" && (
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <textarea
                                className={`form-control form-control-lg`}
                                placeholder=""
                                disabled
                              ></textarea>
                            </div>
                          </div>
                        )}
                        {question.answerType === "datetime" && (
                          <>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  className={`form-control form-control-lg`}
                                  type="text"
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {question.answerType === "date" && (
                          <>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  className={`form-control form-control-lg`}
                                  type="text"
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {question.answerType === "time" && (
                          <>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  className={`form-control form-control-lg`}
                                  type="text"
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {question.answerType === "dropdown" && (
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <select
                                  className={`form-control form-control-lg`}
                                  disabled
                                >
                                  <option value="">Select an option</option>
                                  {question.options.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                        {question.answerType === "checkbox" && (
                          <>
                            {question.options.map((option, index) => (
                              <div
                                className="form-group"
                                key={`options_${index}`}
                              >
                                <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                                  <input
                                    type="checkbox"
                                    className={`custom-control-input vdrSelected`}
                                    disabled
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`question_checkbox_${question.id}_${index}`}
                                  >
                                    {option}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {question.answerType === "radio" && (
                          <div className="form-pm-group">
                            <ul className="buysell-pm-list">
                              {question.options.map((option, index) => (
                                <li className="buysell-pm-item">
                                  <input
                                    className="buysell-pm-control"
                                    type="radio"
                                    disabled
                                  />
                                  <label className="buysell-pm-label">
                                    <span className="pm-name">{option}</span>
                                    <span className="pm-icon">
                                      <em className="icon ni ni-question"></em>
                                    </span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {question.answerType === "image_input" && (
                          <div className="form-group">
                            <div
                              className="form-control-group"
                              id="fileContainer"
                            >
                              <input
                                type="file"
                                id="add_picture"
                                className="file-block"
                                name="add_picture"
                                accept=".png, .jpg, .jpeg, .pdf, .doc, docx, .xlx, .xlxs, .csv"
                                disabled
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormRenderer;
