import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { THEME_COLOR } from "./../../config/config";

const useStyles = makeStyles((theme) => ({
  highlightedRow: {
    backgroundColor: "#d3d3d3",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
}));

const theme = createMuiTheme({
  shadows: Array(5).fill("none"),
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        zIndex: 0,
      },
      fixedHeader: {
        zIndex: 0,
      },
    },
    MuiTableFooter: {
      root: {
        background: "#f0f2f4",
      },
    },
  },
  MuiFormLabel: {
    zIndex: 0,
  },
  MuiTableHead: { zIndex: 0 },
  palette: {
    primary: {
      main: THEME_COLOR, // your color
    },
  },
});

export default function DataTable(props) {
  const classes = useStyles();
  //console.log("DataTable Props:", props);
  const [sortedData, setSortedData] = useState(props.tableData || []);
  const [columnStyles, setColumnStyles] = useState({});

  useEffect(() => {
    if (props.tableData && props.accountnoFromParams && props.log_type) {
      let matchingRowIndex = -1;

      if (props.log_type === "agreements") {
        matchingRowIndex = props.tableData.findIndex(
          (row) =>
            Object.values(row).includes(props.accountnoFromParams) &&
            Object.values(row).includes(props.agreement_accountno)
        );
      } else {
        matchingRowIndex = props.tableData.findIndex((row) =>
          Object.values(row).includes(props.accountnoFromParams)
        );
      }

      if (matchingRowIndex !== -1) {
        const updatedTableData = [...props.tableData];
        const [matchingRow] = updatedTableData.splice(matchingRowIndex, 1);
        setSortedData([matchingRow, ...updatedTableData]);
      }
    }

    // Set column styles based on screen size
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 900) {
        if (props.from === "clients") {
          setColumnStyles({ maxWidth: "150px", whiteSpace: "wrap" });
        } else if (props.from === "agents") {
          setColumnStyles({ whiteSpace: "wrap" });
        } else if (props.from === "rfqs") {
          setColumnStyles({ maxWidth: "150px", whiteSpace: "wrap" });
        } else if (props.from === "orders") {
          setColumnStyles({ maxWidth: "150px", whiteSpace: "wrap" });
        } else if (props.from === "quotations") {
          setColumnStyles({ maxWidth: "150px", whiteSpace: "wrap" });
        } else {
          setColumnStyles({ whiteSpace: "wrap" });
        }
      } else {
        setColumnStyles({ whiteSpace: "wrap" });
      }
    };

    handleResize(); // Set initial style
    window.addEventListener("resize", handleResize); // Update style on resize

    return () => window.removeEventListener("resize", handleResize);
  }, [props.tableData, props.accountnoFromParams, props.log_type]);

  const options = {
    filter: true,
    tableBodyHeight: "100%",
    selectableRows: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [25, 50, 100],
    jumpToPage: true,
    rowHover: true,
    setRowProps: (row) => {
      if (props.accountnoFromParams && props.tableData) {
        let isAccountInRow = false;

        if (props.log_type === "agreements") {
          isAccountInRow =
            Object.values(row).includes(props.accountnoFromParams) &&
            Object.values(row).includes(props.agreement_accountno);
        } else {
          isAccountInRow = Object.values(row).includes(
            props.accountnoFromParams
          );
        }

        return {
          className: isAccountInRow ? classes.highlightedRow : "",
        };
      }
    },
  };

  const columns = props.columns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      setCellProps: () => ({ style: columnStyles }),
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.tableContainer}>
        <MUIDataTable
          title={props.title}
          data={sortedData}
          columns={columns}
          options={options}
          id="dTable"
        />
      </div>
    </ThemeProvider>
  );
}
