import { React } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import {
  ValidateEmail,
  normalize,
  formatAsCurrency,
} from "../../../config/utility";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import dateFormat, { masks } from "dateformat";
import {
  listAgents,
  regenerateLink,
  cancelLink,
  getSingleAgent,
  editAgent,
  listServicesAgents,
  assignServicesToAgent,
  sendEmail,
  listAgentSignedAgreements,
  revokeAgentSignedAgreements,
  sendUnsignedAgentEmail,
  listSubAgent,
  getAgentLogin,
  resendAgentActivation,
  getAgentSignupLink,
  addAgentsCommissionSettings,
  getAgentCommissionSettings,
  getAgentCommissionStatistics,
  addFundsInAgentsAccount,
  approveAgentSignedAgreement,
  sendAgentInvite,
  deleteInviteAgent,
} from "./../../../config/api_calls";
import {
  ListAgentAssignRoles,
  editAgentAssignRole,
  ListSubAgentAssignRoles,
  editSubAgentAssignRole,
} from "../../../config/agent_role_api_calls";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import { UpworkJobLoader } from "../../../components/ContentLoaders/newLoaders/UpworkJobLoader";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import Checkbox from "@material-ui/core/Checkbox";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import localization from '../../../localization/enUS';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const auth = new HelperClass();
class Agents extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      subAgentsData: [],
      errorMessage: "",
      errorMessageEdit: "",
      agentId: "",
      successMessage: "",
      successMessageEdit: "",
      eSuccessMessage: "",
      eErrorMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      title: "",
      send_email: "",
      email_to: "",
      name: "",
      logo: "",
      agent_email: "",
      agent_phone: "",
      agent_company: "",
      agent_status: "",
      agent_password: "",
      agent_address: "",
      agent_address2: "",
      agent_city: "",
      agent_state: "",
      agent_zip: "",
      agent_country: "",
      sharelink_limit: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      already_activated: false,
      c_password: "",
      agent_id: "",
      loginErrorMessage: "",
      login_url: "",
      serviceData: [],
      signedData: [],
      unsignedData: [],
      isEmailSending: false,
      unsigned_email_agreement_id: 0,
      revoke_reason: "",
      isRevokeReasonActive: false,
      isApproveActive: false,
      approve_agreement_id: "",
      approve_agreement_accountno: "",
      tableLoaderListing: true,
      columnBundleServices: [],
      signup_link_agent: "",
      login_url_agent: "",
      commission_button: false,
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commissionErrorTitle: "",
      commission_type: "paid_on_collected",
      commission_value_type: "percentage",
      apply_commission: "",
      commission_value: "",
      copied1: false,
      copied2: false,
      agent_total_sales: 0,
      agent_total_commision: 0,
      agent_paid_commision: 0,
      agent_pending_commision: 0,
      show_total_sales: true,
      show_total_commision: true,
      show_paid_commision: true,
      show_pending_commision: true,
      pay_now_button: false,
      pay_now_form: false,
      payment_amount: 0,
      fundErrorMessage: "",
      fundSuccessMessage: "",
      add_funds_button: false,
      notifyCheckBox: false,

      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubAgentRole: "",
      successMessageSubAgentRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",
      statusChanged: false,

      errorName: "",
      errorAgentStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
      errorAgentSharelink: "",

      agentErrorMessage: "",
      agentSuccessMessage: "",
      agent_name: "",
      agent_email: "",
      activeTab: localStorage.getItem("agent_activeTab") || "tabItem6",
      agentInviteErrorMessage: "",
      agentInviteSuccessMessage: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",

      agent_isVerified: "",

      columnServices: [
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        { name: "Company" },
        { name: "Name" },
        { name: "Email" },
        {
          name: "Phone",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setPhone(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setDomain(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Sub CP Signup Link",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => this.subAgentLink(value, tableMeta)}
                            title="Sub Channel Partner Signup Link"
                            disabled={
                              this.props.is_msa_signed === "No" ||
                              this.props.defaultSettings === "missing"
                                ? true
                                : false
                            }
                            className=" btn btn-icon  btn-outline-light"
                          >
                            <em className="icon ni ni-link-h"></em>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "ID",
          options: {
            display: false,
          },
        },
        {
          name: "Account#",
          options: {
            display: false,
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let agent_status_array = tableMeta.rowData[5].split("__");
              let agent_status = agent_status_array[0];
              let agent_verified = agent_status_array[1];
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.editAgentForm(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Channel Partner"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>{localization.agentsListing.listingLink1}</span>
                                </a>
                              </li>
                              {/*{agent_status === "active" && agent_verified === "No" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.verifyAccount(
                                        value,
                                        tableMeta
                                      )
                                    }
                                    title="Verify this account."
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-signin"></em>
                                    <span>Verify Account</span>
                                  </a>
                                </li>
                              ) : null}*/}
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.agentAgreements(value, tableMeta)
                                  }
                                  title="Signed / Un-Signed Channel Partner Agreements"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>{localization.agentsListing.listingLink2}</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.getAgentCommission(value, tableMeta)
                                  }
                                  title="Channel Partner commission structure and earned commission."
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>{localization.agentsListing.listingLink3}</span>
                                </a>
                              </li>
                              {agent_status === "inactive" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.resendAgentActivation(
                                        value,
                                        tableMeta
                                      )
                                    }
                                    title="Resend activation link if account is not verified."
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-signin"></em>
                                    <span>Resend Activation</span>
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subAgents(value, tableMeta)
                                  }
                                  title="Sub Channel Partner"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-user-list"></em>
                                  <span>{localization.agentsListing.listingLink4}</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.agentAssignRole(value, tableMeta)
                                  }
                                  title="Assign Menu"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>{localization.agentsListing.listingLink5}</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.agentLogin(value, tableMeta)
                                  }
                                  title="Login to the Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>{localization.agentsListing.listingLink6}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      
columnServices_invitedAgents: [
  { name: "Agent NAME" },
  { name: "Agent EMAIL" },
  {
    name: "INVITED ON",
  },
  { name: "ID", options: { display: false } },
  {
    name: "Actions",
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <div
              className="nk-tb-col nk-tb-col-tools"
              style={{ padding: "0px" }}
            >
              <ul className="nk-tb-actions gx-1">
                <li>
                  <div className="drodown">
                    <a
                      href="#"
                      className="dropdown-toggle btn btn-icon btn-outline-light"
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-more-h"></em>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <a
                            onClick={() =>
                              this.deleteAlert(tableMeta.rowData[3])
                            }
                            style={{ cursor: "pointer" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Agreement"
                            disabled={
                              this.props.is_msa_signed === "No" ||
                              this.props.defaultSettings === "missing"
                                ? true
                                : false
                            }
                          >
                            <em className="icon ni ni-trash"></em>
                            <span>Delete Invite</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              this.resendInvite(
                                tableMeta.rowData[1],
                                tableMeta.rowData[0]
                              )
                            }
                            style={{ cursor: "pointer" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Resend Activation"
                            disabled={
                              this.props.is_msa_signed === "No" ||
                              this.props.defaultSettings === "missing"
                                ? true
                                : false
                            }
                          >
                            <em className="icon ni ni-signin"></em>
                            <span>Resend Invite</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        );
      },
    },
  },
],
    };
    // Bind the event handler functions to the current instance
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.countryOptions = countryList().getData();
  }

  async componentDidMount() {
    const storedTab = localStorage.getItem("agent_activeTab");
    if (storedTab) {
      this.setState({ activeTab: storedTab });
    }
    const servicesResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableData_invitedAgents: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableData_invitedAgents: servicesResponce.data.invitations,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableData_invitedAgents: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          agent_city: city && city !== "" ? city : "",
          agent_state: state && state !== "" ? state : "",
          agent_zip: zipCode && zipCode !== "" ? zipCode : "",
          agent_country: country && country !== "" ? country : "",
          agent_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          agent_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  resendAgentActivation = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    let status = tableMeta.rowData[5];

    let status_str = status.split("__");
    let is_verified = status_str[1];

    if (is_verified === "Yes") {
      this.setState({
        already_activated: true,
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("show");
    } else {
      this.setState({
        activation_button: true,
        activation_tick: false,
        activation_cross: false,
        already_activated: false,
      });
      window.$("#modalAlertResend").modal("show");
      const kycResponce = await resendAgentActivation(
        auth.getAccount(),
        auth.getToken(),
        agent_id
      );
      //console.log("resendAgentActivation: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: true,
          already_activated: false,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "not_exists"
      ) {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: true,
          already_activated: false,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "verification_not_found"
      ) {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: false,
          already_activated: true,
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          activation_button: false,
          activation_tick: true,
          activation_cross: false,
          already_activated: false,
        });
      } else {
        this.setState({
          activation_button: false,
          activation_tick: false,
          activation_cross: true,
          already_activated: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 5000);
  };

  editAgentForm = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    const servicesResponce = await getSingleAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let agent_status = "0";
      if (
        servicesResponce.data.data[0][6] &&
        servicesResponce.data.data[0][6] === "active"
      ) {
        agent_status = "1";
      }
      this.setState({
        title: servicesResponce.data.data[0][0],
        name: servicesResponce.data.data[0][1],
        agent_email: servicesResponce.data.data[0][2],
        agent_phone: servicesResponce.data.data[0][3],
        agent_id: servicesResponce.data.data[0][4],
        agent_company: servicesResponce.data.data[0][5],
        agent_status: agent_status,
        sharelink_limit: servicesResponce.data.data[0][7],
        agent_address: servicesResponce.data.data[0][8],
        agent_address2: servicesResponce.data.data[0][9],
        agent_city: servicesResponce.data.data[0][10],
        agent_state: servicesResponce.data.data[0][11],
        agent_zip: servicesResponce.data.data[0][12],
        agent_country: servicesResponce.data.data[0][13],
        agent_isVerified: servicesResponce.data.data[0][14],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;

    this.setState({
      agent_country: country,
    });
  };

  subAgentLink = (value, tableMeta) => {
    //console.log("this.state.reg_button: ", this.state.reg_button);

    this.setState({
      copied: false,
      signup_link: tableMeta.rowData[6],
      current_id: tableMeta.rowData[7],
      errorMessage: "",
      successMessage: "",
    });
    window.$("#modalSignupLink").modal("show");
  };

  setDomain = (value, tableMeta) => {
    //console.log("tableMeta.rowData[5]: ", tableMeta.rowData[5]);
    let domain_str = tableMeta.rowData[5].split("__");
    let domain_verified = domain_str[1];
    let status = domain_str[0];

    if (status === "active") {
      if (domain_verified === "Yes") {
        return <span className="badge badge-outline-success">Active</span>;
      } else {
        return <span className="badge badge-outline-danger">Not Verified</span>;
      }
    } else {
      return <span className="badge badge-outline-danger">Inactive</span>;
    }
  };

  setPhone = (value, tableMeta) => {
    //console.log("tableMeta.rowData[5]: ", tableMeta.rowData[5]);
    if (tableMeta.rowData[4] !== "") {
      //let phone = tableMeta.rowData[4].replace("+1", "");
      let phone = this.formatPhoneNumber(tableMeta.rowData[4]);
      return phone;
    } else {
      return "---";
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  agentLogin = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    this.setState({
      loginErrorMessage: "",
      login_url: "",
    });
    window.$("#modalDefaultLogin").modal("show");
    const servicesResponce = await getAgentLogin(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("agentLogin.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "inactive"
    ) {
      this.setState({
        loginErrorMessage: "Account is not active.",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "notVerified"
    ) {
      this.setState({
        loginErrorMessage:
          "Account is not verifed. Please check your email and click verify link.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        login_url: servicesResponce.data.login_url,
      });

      window.open(servicesResponce.data.login_url, "_blank");
      window.$("#modalDefaultLogin").modal("hide");
    } else {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    }
  };

  show_email = () => {
    this.setState({
      send_email: "1",
    });
  };

  send_email = async () => {
    let { email_to } = this.state;
    this.setState({
      eSuccessMessage: "",
      eErrorMessage: "",
    });
    let is_validated = true;
    if (this.state.email_to === "") {
      is_validated = false;
    }
    if (is_validated) {
      let apiResponce = await sendEmail(
        auth.getAccount(),
        auth.getToken(),
        this.state.email_to,
        this.state.signup_link,
        this.state.current_id
      );
      console.log("apiResponce.send_email: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          eSuccessMessage: "Email sent successfully.",
          eErrorMessage: "",
          disabled: false,
        });
      } else {
        this.setState({
          eErrorMessage: "Something went wrong,try again later.",
          disabled: false,
          eSuccessMessage: "",
        });
      }
    } else {
      this.setState({
        eSuccessMessage: "",
        eErrorMessage: "Please enter the email address.",
      });
    }
    setTimeout(() => {
      this.setState({
        eSuccessMessage: "",
        eErrorMessage: "",
      });
    }, 4000);
  };

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateLink(
      auth.getAccount(),
      auth.getToken(),
      this.state.current_id
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link regenerated successfully.",
        reg_button: false,
      });
      //this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelLink(
      auth.getAccount(),
      auth.getToken(),
      this.state.current_id
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
  };

  showServices = async (value, tableMeta) => {
    const servicesResponce = await listServicesAgents(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("showServices.data: ", tableMeta);
    this.setState({
      agent_id: tableMeta.rowData[7],
      company_title: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      errorMessage: "",
      successMessage: "",
      emptyService: 0,
    });

    window.$("#modalFormDetailService").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    }
  };

  agentAgreements = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    const servicesResponce = await listAgentSignedAgreements(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    //console.log("showAgreements.data: ", servicesResponce.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
      signedData: [],
      unsignedData: [],
      agent_id: agent_id,
      revoke_reason: "",
      isRevokeReasonActive: false,
      company_title: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
    });

    window.$("#modalFormAgentAgreements").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage: "There is some error while getting the agreements.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the agreements.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signedData: servicesResponce.data.signedArray,
        unsignedData: servicesResponce.data.unsignedArray,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while getting the agreements.",
      });
      //window.location.replace("/error");
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];
    //console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  subAgents = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    const servicesResponce = await listSubAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    //console.log("subAgents.data: ", servicesResponce.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
      agent_id: agent_id,
    });

    window.$("#modalFormSubAgents").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There are no sub channel partners.",
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        subAgentsData: servicesResponce.data.data,
        columnBundleServices: [
          { name: "Date Added" },
          { name: "Title" },
          { name: "Name" },
          { name: "Email" },
          {
            name: "Status",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.setStatus(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "Action",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.subagentAssignRole(value, tableMeta)
                                    }
                                    title="Assign Menu"
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-grid-c"></em>
                                    <span>Assign Menu</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
        errorMessage: "",
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  };

  renderServices = () => {
    let service_data = this.state.serviceData;
    //console.log("Render Data: ", service_data);
    let didArray = [];
    let tfnArray = [];
    let termArray = [];
    let smsArray = [];
    let ivrArray = [];
    let liveagentArray = [];
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][5] == "did") {
        let insideArray1 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        didArray.push(insideArray1);
      }

      if (service_data[i][5] == "tfn") {
        let insideArray2 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        tfnArray.push(insideArray2);
      }
      if (service_data[i][5] == "term") {
        let insideArray3 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        termArray.push(insideArray3);
      }
      if (service_data[i][5] == "ivr") {
        let insideArray5 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        ivrArray.push(insideArray5);
      }
      if (service_data[i][5] == "live_agent") {
        let insideArray6 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        liveagentArray.push(insideArray6);
      }
      if (service_data[i][5] == "sms") {
        let insideArray4 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        smsArray.push(insideArray4);
      }
    }
    //console.log("didArray: ", didArray);
    //console.log("tfnArray: ", tfnArray);
    //console.log("termArray: ", termArray);
    //console.log("smsArray: ", smsArray);
    //console.log("ivrArray: ", ivrArray);
    // console.log("liveagentArray: ", liveagentArray);
    this.setState({
      didArray: didArray,
      tfnArray: tfnArray,
      termArray: termArray,
      smsArray: smsArray,
      ivrArray: ivrArray,
      liveagentArray: liveagentArray,
    });
  };

  formSaveServices = async () => {
    let {
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service,
    } = this.state;
    await this.setPriceStates(
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service
    );
    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      disabled: true,
    });

    const saveResponce = await assignServicesToAgent(
      auth.getAccount(),
      auth.getToken(),
      did_service,
      tfn_service,
      term_service,
      this.state.agent_id,
      this.state.nrc_did,
      this.state.mrc_did,
      this.state.rate_did,
      this.state.nrc_tfn,
      this.state.mrc_tfn,
      this.state.rate_tfn,
      this.state.rate_term,
      sms_service,
      this.state.rate_smsin,
      this.state.rate_smsout,
      ivr_service,
      liveagent_service,
      this.state.rate_ivr,
      this.state.rate_liveagent
    );
    //console.log("Assign Result: ", saveResponce.data.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Services assigned successfully.",
        disabled: false,
      });

      $("#viewBtn").show();
      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDetail").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning the services.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  formUpdateSubmit = async () => {
    let {
      title,
      name,
      agent_email,
      agent_password,
      c_password,
      agent_phone,
      agent_company,
      agent_status,
      sharelink_limit,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
      agent_country,
      statusChanged,
      agent_isVerified
    } = this.state;

    this.setState({
      errorMessageEdit: "",
      errorName: "",
      errorStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
      errorPass: "",
      errorAgentSharelink: "",
    });
    const modalBody = document.getElementById("modalFormDetail");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    let is_validated = true;
    if (this.state.name === "") {
      this.setState({
        errorName: "error",
        errorMessageEdit: "Channel partner Name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_company === "") {
      this.setState({
        errorAgentCompany: "error",
        errorMessageEdit: "Company name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorAgentPhone: "error",
        errorMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(this.state.agent_phone)) {
      this.setState({
        errorAgentPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(this.state.agent_email) === false) {
      this.setState({
        errorAgentEmail: "error",
        errorMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorAgentPassword: "error",
          errorMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorCPassword: "error",
          errorMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorCPassword: "error",
          errorMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorCPassword: "error",
          errorMessageEdit: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    } else if (this.state.agent_address === "") {
      this.setState({
        errorAgentAddress: "error",
        errorMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.agent_city === "") {
      this.setState({
        errorAgentCity: "error",
        errorMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (this.state.agent_state === "") {
      this.setState({
        errorAgentState: "error",
        errorMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (this.state.agent_zip === "") {
      this.setState({
        errorAgentZip: "error",
        errorMessageEdit: "Zip/postal code is required.",
      });
      is_validated = false;
    } else if (this.state.agent_country === "") {
      this.setState({
        errorAgentCountry: "error",
        errorMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (this.state.sharelink_limit === "") {
      this.setState({
        errorAgentSharelink: "error",
        errorMessageEdit: "Share link limit is required.",
      });
      is_validated = false;
    }
    if (is_validated) {
      let apiResponce = await editAgent(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        agent_email,
        agent_password,
        agent_phone,
        this.state.agent_id,
        agent_company,
        agent_status,
        sharelink_limit,
        agent_address,
        agent_address2,
        agent_city,
        agent_state,
        agent_zip,
        agent_country,
        this.state.notifyCheckBox.toString(),
        statusChanged,
        agent_isVerified
      );
      //console.log("Edit Agent RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";
        this.setState({
          errorMessageEdit: formattedErrorMessage,
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "Channel partner updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.replace("/agents");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "agent_status") {
      this.setState({ statusChanged: true });
      console.log("Status Changed:", true);
    }
    this.setState({
      [name]: value,
    });
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  downloadPdf = async (d_f_name, title) => {
    if (d_f_name) {
      var url =
        process.env.REACT_APP_AGENT_PORTAL_URL +
        "files_data/agreements/" +
        d_f_name;
      //console.log("File URL: ", url);
      var pdf_name = title.replace(/[^a-zA-Z ]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = pdf_name + ".pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  revokeAgreement = async (agreement_id, agreement_accountno) => {
    this.setState({
      isRevokeReasonActive: true,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      revokeAgreementData: {
        agreement_id: agreement_id,
        agreement_accountno: agreement_accountno,
      },
    });
    //console.log("agreement_accountno: ", agreement_accountno);
  };

  approveAgreement = async (agent_id) => {
    const { approve_agreement_accountno, approve_agreement_id } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      disabled: true,
    });

    const servicesResponce = await approveAgentSignedAgreement(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      approve_agreement_id,
      approve_agreement_accountno
    );
    console.log("approveAgreement.data: ", servicesResponce.data);
    this.setState({
      disabled: false,
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.reloadAgentAgreements(agent_id);
      this.setState({
        successMessage: "Agreement approved successfully",
        approve_agreement_id: "",
        approve_agreement_accountno: "",
        isApproveActive: false,
      });
    } else {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
    }

    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  reloadAgentAgreements = async (agent_id) => {
    const servicesResponce = await listAgentSignedAgreements(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("showAgreements.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
    } else if (servicesResponce.data.status === 404) {
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signedData: servicesResponce.data.signedArray,
        unsignedData: servicesResponce.data.unsignedArray,
        errorMessage: "",
      });
      //this.renderServices();
    } else {
      //window.location.replace("/error");
    }
  };

  sendEmailUnsignedAgreement = async (
    agreement_id,
    agreement_accountno,
    agent_id
  ) => {
    this.setState({
      successMessage: "",
      errorMessage: "",
      isEmailSending: true,
      unsigned_email_agreement_id: agreement_id,
    });

    const servicesResponce = await sendUnsignedAgentEmail(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      agreement_id,
      agreement_accountno
    );

    this.setState({
      isEmailSending: false,
    });

    console.log("sendEmailUnsignedAgreement.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while sending an email. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while sending an email. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessage: "Email Sent Successfully",
      });
      setTimeout(function () {
        window.$("#modalFormAgentAgreements").modal("hide");
      }, 4000);
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  handleAgentAgreementRevokeSubmit = async (
    agreement_id,
    agreement_accountno,
    agent_id,
    revoke_reason
  ) => {
    //console.log('handleAgentAgreementRevokeSubmit',agent_id, agreement_id, revoke_reason, agreement_accountno);
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        errorMessage: "Revoke Reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessage: "",
        errorMessage: "",
        disabled: true,
      });

      const servicesResponce = await revokeAgentSignedAgreements(
        auth.getAccount(),
        auth.getToken(),
        agent_id,
        agreement_id,
        agreement_accountno,
        revoke_reason
      );
      console.log(
        "handleAgentAgreementRevokeSubmit.data: ",
        servicesResponce.data
      );
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Agreement is revoked successfully",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
        setTimeout(function () {
          window.$("#modalFormAgentAgreements").modal("hide");
        }, 4000);
      } else {
        this.setState({
          errorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handlePhoneNumberChange(value) {
    this.setState({
      agent_phone: value,
    });
  }

  getSignUpLink = async () => {
    this.setState({
      tableLoader: true,
    });
    window.$("#modalgetSignupLink").modal("show");
    const servicesResponce = await getAgentSignupLink(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoader: true,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link_agent: servicesResponce.data.data,
        login_url_agent: servicesResponce.data.login_url,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableLoader: true,
      });
      // window.location.replace("/error");
    }
  };

  getAgentCommission = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    this.setState({
      company_title: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      agent_id: agent_id,
      fundErrorMessage: "",
      fundSuccessMessage: "",
    });
    window.$("#modalAgentCommission").modal("show");
    this.get_commission_statistics(agent_id);
  };

  get_commission_statistics = async (agent_id) => {
    //const { agent_id } = this.state;
    const servicesResponce = await getAgentCommissionStatistics(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("get_commission_statistics: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        show_total_sales: false,
        show_total_commision: false,
        show_paid_commision: false,
        show_pending_commision: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agent_total_sales: servicesResponce.data.total_sales
          ? servicesResponce.data.total_sales
          : 0,
        agent_total_commision: servicesResponce.data.total_commission
          ? servicesResponce.data.total_commission
          : 0,
        agent_paid_commision: servicesResponce.data.total_paid
          ? servicesResponce.data.total_paid
          : 0,
        agent_pending_commision: servicesResponce.data.total_pending
          ? servicesResponce.data.total_pending
          : 0,
        show_total_sales: false,
        show_total_commision: false,
        show_paid_commision: false,
        show_pending_commision: false,
      });
    } else {
      this.setState({
        show_total_sales: false,
        show_total_commision: false,
        show_paid_commision: false,
        show_pending_commision: false,
      });
    }
  };

  addFundsInAgentAccount = async () => {
    const { agent_id, payment_amount, agent_pending_commision } = this.state;
    this.setState({
      add_funds_button: true,
      fundErrorMessage: "",
      fundSuccessMessage: "",
    });
    if (payment_amount === 0) {
      this.setState({
        fundErrorMessage: "Amount should be greater than 0.",
        add_funds_button: false,
      });
    } else if (payment_amount > agent_pending_commision) {
      this.setState({
        fundErrorMessage:
          "Amount should be less than or equal to pending amount.",
        add_funds_button: false,
      });
    } else {
      const servicesResponce = await addFundsInAgentsAccount(
        auth.getAccount(),
        auth.getToken(),
        agent_id,
        payment_amount
      );
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          fundErrorMessage:
            "There is some error while adding the funds. Please try again.",
          add_funds_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          fundErrorMessage: "",
          fundSuccessMessage:
            "Funds added successfully to the channel partner account.",
          agent_paid_commision: servicesResponce.data.total_paid
            ? servicesResponce.data.total_paid
            : 0,
          agent_pending_commision: servicesResponce.data.total_pending
            ? servicesResponce.data.total_pending
            : 0,
          add_funds_button: false,
        });
      } else {
        this.setState({
          fundErrorMessage:
            "There is some error while adding the funds. Please try again.",
          add_funds_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        fundErrorMessage: "",
        fundSuccessMessage: "",
        add_funds_button: false,
      });
    }, 5000);
  };

  get_commission_settings = async () => {
    const { agent_id } = this.state;
    const servicesResponce = await getAgentCommissionSettings(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("get_commission_settings: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        apply_commission: servicesResponce.data.data[0].apply_commission,
        commission_type: servicesResponce.data.data[0].commission_type,
        commission_value: servicesResponce.data.data[0].commission_value,
        commission_value_type:
          servicesResponce.data.data[0].commission_value_type,
      });
    } else {
    }
  };

  update_commission_settings = async () => {
    let {
      commission_type,
      commission_value_type,
      commission_value,
      apply_commission,
      agent_id,
    } = this.state;
    this.setState({
      commissionErrorTitle: "",
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commission_button: true,
    });
    var is_validated = true;
    console.log("agent_id: ", agent_id);
    if (commission_value === "") {
      this.setState({
        commissionErrorTitle: "Missing Field",
        commissionErrorMessage: "Commission value is required.",
        commission_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addAgentsCommissionSettings(
        auth.getAccount(),
        auth.getToken(),
        agent_id,
        commission_type,
        commission_value_type,
        commission_value,
        apply_commission
      );

      console.log("update_commission_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          commissionErrorTitle: "",
          commissionErrorMessage: "",
          commissionSuccessMessage: "Commission settings added successfully.",
          commission_button: false,
        });
      } else {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        commissionErrorMessage: "",
        commissionErrorTitle: "",
        commissionSuccessMessage: "",
      });
    }, 5000);
  };

  agentAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[7];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      checkedName: tableMeta.rowData[2],
      checkedEmail: tableMeta.rowData[3],
    });
    window.$("#modalAgentAssignRole").modal("show");
    this.getAgentRoleData(agentId, agentEmail);
  };

  getAgentRoleData = async (agentId, agentEmail) => {
    const listAgentAssignRoles = await ListAgentAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListAgentRoles Res: ", listAgentAssignRoles.data.data);
    if (
      listAgentAssignRoles.data.status === 403 ||
      listAgentAssignRoles.data.errors === "authentication missing" ||
      listAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentAssignRoles.data.status === 200 &&
      listAgentAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listAgentAssignRoles.data.data.roles;
      const assignSubRoleData = listAgentAssignRoles.data.data.subroles;
      const allRoleData = listAgentAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listAgentAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        agentRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  subagentAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[5];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      checkedName: tableMeta.rowData[2],
      checkedEmail: tableMeta.rowData[3],
    });
    window.$("#modalSubAgentAssignRole").modal("show");
    this.getSubAgentRoleData(agentId, agentEmail);
  };

  getSubAgentRoleData = async (agentId, agentEmail) => {
    const listSubAgentAssignRoles = await ListSubAgentAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListSubAgentRoles Res: ", listSubAgentAssignRoles.data.data);
    if (
      listSubAgentAssignRoles.data.status === 403 ||
      listSubAgentAssignRoles.data.errors === "authentication missing" ||
      listSubAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listSubAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listSubAgentAssignRoles.data.status === 200 &&
      listSubAgentAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listSubAgentAssignRoles.data.data.roles;
      const assignSubRoleData = listSubAgentAssignRoles.data.data.subroles;
      const allRoleData = listSubAgentAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listSubAgentAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        agentRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  changedRolesRights = (event) => {
    const { name, checked } = event.target;
    const { assignRoleData, assignSubRoleData } = this.state;

    // Find the main role in assignRoleData
    const mainRole = assignRoleData.find((item) => item.id === parseInt(name));

    if (!mainRole) {
      return;
    }

    // Update the checked state of the main role
    const updatedChangedRolesRights = this.state.changedRolesRights.map(
      (item) =>
        item.id === mainRole.id ? { ...item, isChecked: checked } : item
    );

    // If the main role is not found in changedRolesRights, add it
    if (!updatedChangedRolesRights.some((item) => item.id === mainRole.id)) {
      updatedChangedRolesRights.push({ ...mainRole, isChecked: checked });
    }

    // Find the associated sub roles
    const associatedSubRoles = assignSubRoleData.filter(
      (subRole) => subRole.parent_role_id === mainRole.parent_role_id
    );

    //console.log("associatedSubRoles ", associatedSubRoles);

    // Update the checked state of associated sub roles
    const updatedSubRolesRights = this.state.changedSubRolesRights.map(
      (subRole) => {
        // Check if the sub role is associated with the main role
        const isAssociated = associatedSubRoles.some(
          (assocSubRole) => assocSubRole.id === subRole.id
        );

        // If associated, update isChecked based on the checked state of the main role
        if (isAssociated) {
          return { ...subRole, isChecked: checked };
        } else {
          return subRole;
        }
      }
    );

    // If associated sub roles are not found in changedSubRolesRights, add them
    associatedSubRoles.forEach((assocSubRole) => {
      if (
        !updatedSubRolesRights.some((subRole) => subRole.id === assocSubRole.id)
      ) {
        updatedSubRolesRights.push({ ...assocSubRole, isChecked: checked });
      }
    });

    // Update the state with the modified arrays
    this.setState({
      changedRolesRights: updatedChangedRolesRights,
      changedSubRolesRights: updatedSubRolesRights,
    });
  };

  subchangedRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subassignRoleData, subassignSubRoleData } = this.state;

    // Find the main role in assignRoleData
    const mainRole = subassignRoleData.find(
      (item) => item.id === parseInt(name)
    );

    if (!mainRole) {
      console.error(`Main role with id '${name}' not found.`);
      return;
    }

    console.log(`Main Role '${mainRole.alt}' isChecked: ${checked}`);

    const updatedChangedRolesRights = this.state.subchangedRolesRights.map(
      (item) =>
        item.id === mainRole.id ? { ...item, isChecked: checked } : item
    );

    if (!updatedChangedRolesRights.some((item) => item.id === mainRole.id)) {
      updatedChangedRolesRights.push({ ...mainRole, isChecked: checked });
    }

    const associatedSubRoles = subassignSubRoleData.filter(
      (subRole) => subRole.parent_role_id === mainRole.parent_role_id
    );

    console.log("associatedSubRoles ", associatedSubRoles);

    const updatedSubRolesRights = this.state.subchangedSubRolesRights.map(
      (subRole) => {
        const isAssociated = associatedSubRoles.some(
          (assocSubRole) => assocSubRole.id === subRole.id
        );
        if (isAssociated) {
          console.log(
            `Sub menu '${subRole.alt}' is associated with main menu '${mainRole.alt}'`
          );
          return { ...subRole, isChecked: checked };
        } else {
          return subRole;
        }
      }
    );

    this.setState({
      subchangedRolesRights: updatedChangedRolesRights,
      subchangedSubRolesRights: updatedSubRolesRights,
    });
  };

  changedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { changedSubRolesRights } = this.state;

    const index = changedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...changedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ changedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        changedSubRolesRights: [
          ...prevState.changedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  subchangedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { subchangedSubRolesRights } = this.state;

    const index = subchangedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...subchangedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ subchangedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        subchangedSubRolesRights: [
          ...prevState.subchangedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  handleSubmitRoleCheckbox = async (name, email) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    // Prepare the CheckRoleFields array with IDs and isactive status
    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    try {
      // Call the backend API to save the roles
      const saveResponce = await editAgentAssignRole(
        auth.getAccount(),
        auth.getToken(),
        CheckRoleFields,
        CheckSubRoleFields,
        name,
        email
      );

      // Handle response status
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        this.setState({
          errorMessageAgentRole:
            "There is some error while updating the Channel Partner menu.",
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageAgentRole: "",
          successMessageAgentRole: "Channel Partner menu updated successfully.",
          disableRoleBtn: false,
        });
        setTimeout(() => {
          window.$("#modalAgentAssignRole").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageAgentRole: saveResponce.data.message,
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      }
    } catch (error) {
      console.error("Error while saving roles:", error);
      this.setState({
        errorMessageAgentRole: "An error occurred while saving menu.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    }
  };

  handleSubmitRoleCheckboxSub = async (name, email) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    // Prepare the CheckRoleFields array with IDs and isactive status
    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    try {
      // Call the backend API to save the roles
      const saveResponce = await editAgentAssignRole(
        auth.getAccount(),
        auth.getToken(),
        CheckRoleFields,
        CheckSubRoleFields,
        name,
        email
      );

      // Handle response status
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        this.setState({
          errorMessageAgentRole:
            "There is some error while updating the Channel Partner menu.",
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageAgentRole: "",
          successMessageAgentRole:
            "Sub Channel Partner menu updated successfully.",
          disableRoleBtn: false,
        });
        setTimeout(() => {
          window.$("#modalSubAgentAssignRole").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageAgentRole: saveResponce.data.message,
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      }
    } catch (error) {
      console.error("Error while saving roles:", error);
      this.setState({
        errorMessageAgentRole: "An error occurred while saving menu.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    }
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  setNewAgentSettings = async () => {
    this.setState({
      agentErrorMessage: "",
      agentSuccessMessage: "",
      agent_name: "",
      agent_email: "",
    });
    window.$("#modalAddNewAgent").modal("show");
  };

  saveNewAgent = async () => {
    const { agent_name, agent_email } = this.state;
    this.setState({
      agentErrorMessage: "",
      agentSuccessMessage: "",
      disabled: true,
    });

    if (agent_name === "") {
      this.setState({
        agentErrorMessage: "Please enter the name.",
        agentSuccessMessage: "",
        disabled: false,
      });
    } else if (agent_email === "") {
      this.setState({
        agentErrorMessage: "Please enter the email address.",
        agentSuccessMessage: "",
        disabled: false,
      });
    } else if (ValidateEmail(agent_email) === false) {
      this.setState({
        agentErrorMessage: "Please enter the valid email address.",
        agentSuccessMessage: "",
        disabled: false,
      });
    } else {
      const servicesResponce = await sendAgentInvite(
        auth.getAccount(),
        auth.getToken(),
        agent_email,
        agent_name
      );

      console.log("agentsResponce.data.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 409) {
        this.setState({
          agentErrorMessage:
            "Channel partner already registered with this email.",
          agentSuccessMessage: "",
          disabled: false,
        });
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          agentErrorMessage:
            "There is some error while sending invite to the channel partner.",
          agentSuccessMessage: "",
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          agentErrorMessage: "",
          agentSuccessMessage:
            "Invite successfully sent to the channel partner.",
          disabled: false,
          client_email: "",
          client_name: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          agentErrorMessage:
            "There is some error while sending invite to the channel partner.",
          agentSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        agentErrorMessage: "",
        agentSuccessMessage: "",
      });
    }, 4000);
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteInviteAgent = async () => {
    let agent_id = this.state.delete_id;

    this.setState({
      agentInviteErrorMessage: "",
      agentInviteSuccessMessage: "",
      disabled: true,
    });

    const userResponce = await deleteInviteAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );

    console.log("deleteAgentInvite: ", userResponce.data);

    if (
      userResponce.data.status === 403 ||
      userResponce.data.errors === "authentication missing" ||
      userResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      userResponce.data.status === 404 &&
      userResponce.data.message === "fail" &&
      userResponce.data.note !== ""
    ) {
      this.setState({
        agentInviteErrorMessage: "Error deleting the invited channel partner.",
        agentInviteSuccessMessage: "",
        disabled: false,
      });
    } else if (
      userResponce.data.status === 200 &&
      userResponce.data.message === "success"
    ) {
      this.setState({
        agentInviteErrorMessage: "",
        agentInviteSuccessMessage:
          "Invited channel partner deleted successfully.",
        disabled: false,
      });

      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
    } else {
      this.setState({
        agentInviteErrorMessage: "Error deleting the invited channel partner.",
        agentInviteSuccessMessage: "",
        disabled: false,
      });
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  resendInvite = async (agent_email, agent_name) => {
    this.setState({
      agentInviteErrorMessage: "",
      agentInviteSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await sendAgentInvite(
      auth.getAccount(),
      auth.getToken(),
      agent_email,
      agent_name
    );

    window.$("#modalresendInvitation").modal("show");

    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        agentInviteErrorMessage: "Error sending invitation email.",
        agentInviteSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState(
        {
          agentInviteErrorMessage: "",
          agentInviteSuccessMessage: "Invitation email sent successfully.",
          disabled: false,
        },
        () => {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        agentInviteErrorMessage: "Error Sending Activation Email",
        agentInviteSuccessMessage: "",
        disabled: false,
      });
    }
  };

  handleTabClick = (tabId) => {
    this.setState({ activeTab: tabId });
    localStorage.setItem("agent_activeTab", tabId);
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    const searchParams = new URLSearchParams(window.location.search);
    const accountno = searchParams.get("accountno");
    const log_type = searchParams.get("log_type");
    return (
      <div className="nk-content " id="Agents_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name={localization.agentsListing.heading}
                        icon="icon ni ni-user-list"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() => this.getSignUpLink()}
                              className="btn btn-info"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-signin"></em>
                              &nbsp;{localization.agentsListing.signupButton}
                            </Link>
                          </li>
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => this.setNewAgentSettings()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;{localization.agentsListing.inviteButton}
                            </button>
                          </li>
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() =>
                                this.addMenuHistory("/agents/addagent")
                              }
                              className="btn btn-primary"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;{localization.agentsListing.addNewButton}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal Start **/}
              <div className="modal fade" tabIndex="-1" id="modalgetSignupLink">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-user-list"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        {localization.agentsListing.signupLinkPopupHeading}
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        <div className="col-xxl-12">
                          {this.state.tableLoader === true ? (
                            HelpLinksLoader()
                          ) : (
                            <>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-user"></em>{" "}
                                      {localization.agentsListing.signupLinkPopupSubHeading1}
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div className="alert alert-light alert-icon">
                                        <em className="icon ni ni-alert-circle"></em>{" "}
                                        {localization.agentsListing.signupLinkPopupSub1Note} {" "}
                                      </div>
                                    </div>

                                    <div
                                      className="caption-text"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-light">
                                          <em className="icon ni ni-link"></em>{" "}
                                          {this.state.signup_link_agent &&
                                          this.state.signup_link_agent !==
                                            "" ? (
                                            <>{this.state.signup_link_agent}</>
                                          ) : (
                                            <>
                                              Channel partner signup link is not
                                              available. Please create a new one
                                              by clicking on "Regenerate"
                                              button.
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      {this.state.signup_link_agent &&
                                      this.state.signup_link_agent !== "" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <CopyToClipboard
                                            text={this.state.signup_link_agent}
                                            onCopy={() =>
                                              this.setState({ copied1: true })
                                            }
                                          >
                                            <button className="btn btn-success">
                                              <em className="icon ni ni-copy"></em>{" "}
                                              Copy to clipboard{" "}
                                              {this.state.copied1 ? (
                                                <span
                                                  style={{ color: "white" }}
                                                >
                                                  <em className="icon ni ni-done"></em>
                                                </span>
                                              ) : null}
                                            </button>
                                          </CopyToClipboard>
                                          <a
                                            href={this.state.signup_link_agent}
                                            target="_blank"
                                            className="btn btn-info mx-2"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              color: "#fff",
                                            }}
                                          >
                                            <em className="icon ni ni-external"></em>
                                            <span>Redirect to url</span>
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-signin"></em>{" "}
                                      {localization.agentsListing.signupLinkPopupSubHeading2}
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div
                                        className="alert alert-light alert-icon"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        <em className="icon ni ni-link"></em>{" "}
                                        {this.state.login_url_agent &&
                                        this.state.login_url_agent !== "" ? (
                                          <>{this.state.login_url_agent}</>
                                        ) : (
                                          <>
                                            Channel partner login link is not
                                            available. Please create a new one
                                            by clicking on "Regenerate" button.
                                          </>
                                        )}
                                      </div>

                                      {this.state.login_url_agent &&
                                      this.state.login_url_agent !== "" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <CopyToClipboard
                                            text={this.state.login_url_agent}
                                            onCopy={() =>
                                              this.setState({ copied2: true })
                                            }
                                          >
                                            <button className="btn btn-success">
                                              <em className="icon ni ni-copy"></em>{" "}
                                              Copy to clipboard{" "}
                                              {this.state.copied2 ? (
                                                <span
                                                  style={{ color: "white" }}
                                                >
                                                  <em className="icon ni ni-done"></em>
                                                </span>
                                              ) : null}
                                            </button>
                                          </CopyToClipboard>
                                          <a
                                            href={this.state.login_url_agent}
                                            target="_blank"
                                            className="btn btn-info mx-2"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              color: "#fff",
                                            }}
                                          >
                                            <em className="icon ni ni-external"></em>
                                            <span>Redirect to url</span>
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal End **/}
              {/** Login Modal Start **/}
              <div className="modal fade" tabIndex="-1" id="modalDefaultLogin">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-user-list"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Login to the Agent
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.loginErrorMessage === "" ? (
                        <div className="alert alert-pro alert-primary">
                          <div className="alert-text">
                            <h6>Connecting.....</h6>
                            <p>
                              Please wait while we are connecting to the agent
                              Login. You will be redirected to the Agent
                              dashboard after the authentication.
                            </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.loginErrorMessage !== "" ? (
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h6>Login Error</h6>
                            <p>{this.state.loginErrorMessage}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/** Login Modal End **/}
              {/* DETAIL MODAL START */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalSignupLink"
              >
                <div className="modal-dialog  modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-link-alt"></em> {localization.agentsListing.subSignupLinkPopupHeading}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ marginBottom: "20px" }}>
                            <div className="example-alert">
                              <div className="alert alert-light alert-icon">
                                <em className="icon ni ni-alert-circle"></em>{" "}
                                {localization.agentsListing.subSignupLinkPopupNote}{" "}
                              </div>
                            </div>
                          </div>
                          <div
                            className="caption-text"
                            style={{ marginBottom: "15px" }}
                          >
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-light">
                                {this.state.signup_link}
                              </div>
                            </div>
                            {this.state.reg_button === false ? (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.regenerate_link()}
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}

                            {this.state.cancel_button === false ? (
                              <button
                                className="btn btn-danger"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.cancel_link()}
                              >
                                <em className="icon ni ni-cross-round"></em>{" "}
                                Cancel
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-cross-round"></em>{" "}
                                Cancel
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}

                            <CopyToClipboard
                              text={this.state.signup_link}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <button className="btn btn-success">
                                <em className="icon ni ni-copy"></em> Copy to
                                clipboard{" "}
                                {this.state.copied ? (
                                  <span style={{ color: "white" }}>
                                    <em className="icon ni ni-done"></em>
                                  </span>
                                ) : null}
                              </button>
                            </CopyToClipboard>
                            <button
                              className="btn btn-warning"
                              style={{ marginLeft: "15px" }}
                              onClick={() => this.show_email()}
                            >
                              <em className="icon ni ni-emails"></em> Email Link
                            </button>
                          </div>
                          {this.state.send_email === "1" ? (
                            <div
                              className="row g-4 mt-4"
                              style={{ marginTop: "25px" }}
                            >
                              {this.state.eErrorMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.eErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.eSuccessMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.eSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-7">
                                <MutextField
                                  required
                                  id="email_to"
                                  name="email_to"
                                  type="email"
                                  label="Enter Email Address"
                                  defaultValue={this.state.email_to}
                                  inputProps={{ maxLength: 100 }}
                                  onChange={this.handleChange}
                                  helperText="Enter your Email Address here."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              <div className="col-md-5">
                                <button
                                  className="btn btn-lg btn-primary"
                                  onClick={() => this.send_email()}
                                  disabled={this.state.disabled}
                                >
                                  <em className="icon ni ni-emails"></em> Send
                                  Email Link
                                </button>
                                <button
                                  className="btn btn-lg btn-light"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() =>
                                    this.setState({ send_email: "" })
                                  }
                                >
                                  <em className="icon ni ni-minimize-alt"></em>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Channel Partner</h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.errorMessageEdit,
                            }}
                          ></p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="name"
                                name="name"
                                type="text"
                                label="Channel Partner Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.name}
                                onChange={this.handleChange}
                                helperText="Enter the name."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorName === "error"}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="title"
                                name="title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Channel Partner Title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                helperText="Enter the title here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_company"
                                name="agent_company"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                label="Company Name"
                                value={this.state.agent_company}
                                onChange={this.handleChange}
                                helperText="Enter the company name here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentCompany === "error"}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_email"
                                name="agent_email"
                                type="email"
                                label="Email Address"
                                value={this.state.agent_email}
                                onChange={this.handleChange}
                                helperText="Enter your email address here. (Read Only)"
                                variant="outlined"
                                
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              {/*<PhoneInput
                                required
                                defaultCountry={"us"}
                                inputProps={{
                                  name: "agent_phone",
                                  id: "agent_phone",
                                  maxLength: 20,
                                }}
                                value={this.state.agent_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "agent_phone", value },
                                  })
                                }
                                helperText="Enter the phone number here."
                                label="Phone Number"
                              />*/}
                              <PhoneInput
                                required
                                defaultCountry={"us"}
                                inputProps={{
                                  name: "agent_phone",
                                  id: "agent_phone",
                                  maxLength: 20,
                                  style: this.state.errorAgentPhone
                                    ? { borderColor: "red" }
                                    : {},
                                }}
                                dialCodePreviewStyleProps={{
                                  style: this.state.errorAgentPhone
                                    ? { borderColor: "red" }
                                    : {},
                                }}
                                value={this.state.agent_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "agent_phone", value },
                                  })
                                }
                                helperText="Enter the phone number here."
                                label="Phone Number"
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="agent_status"
                                name="agent_status"
                                select
                                label="Status"
                                value={this.state.agent_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="e.g Active or Inactive"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="status1" value="1">
                                  Active
                                </option>
                                <option key="status0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>
                            <div className="form-group col-md-6">
                              <div
                                class="nk-block-between-md"
                                style={{
                                  position: "absolute",
                                  zIndex: 9999,
                                  right: "13px",
                                  top: "-15px",
                                }}
                              >
                                <div class="nk-block-head-content">
                                  <label
                                    htmlFor="googleLocation-autocomplete"
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    {" "}
                                  </label>
                                </div>
                                <div class="nk-block-head-content">
                                  <ul class="nk-block-tools gx-3">
                                    <li>
                                      <a
                                        onClick={this.handleToggle}
                                        class="text-primary fs-11"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {this.state.toggleButtonText}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {this.state.useGooglePlaces ? (
                                <GooglePlacesAutocomplete
                                  apiKey={
                                    process.env
                                      .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                  }
                                  selectProps={{
                                    value: this.state.agent_address
                                      ? {
                                          label: this.state.agent_address,
                                          value: this.state.agent_address,
                                        }
                                      : null,
                                    onChange: this.handleAddressSelect,
                                    placeholder: this.state.agent_address
                                      ? null
                                      : "Enter your address..",
                                    styles: {
                                      input: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        height: "47px",
                                      }),
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: "black",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9,
                                      }),
                                    },
                                  }}
                                  className="googleLocation-autocomplete"
                                />
                              ) : (
                                <MutextField
                                  required
                                  id="agent_address"
                                  name="agent_address"
                                  type="text"
                                  label="PO Box Address"
                                  value={this.state.agent_address}
                                  onChange={this.handleChange}
                                  inputProps={{ maxLength: 100 }}
                                  variant="outlined"
                                  helperText="Please provide your complete address, excluding city, state, and zip."
                                  fullWidth
                                  error={this.state.errorAgentAddress === "error"}
                                />
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="agent_address2"
                                name="agent_address2"
                                type="text"
                                label="Address 2"
                                value={this.state.agent_address2}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                helperText="If needed, enter additional address details, such as apartment number, suite, or unit."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-2">
                              <MutextField
                                required
                                id="agent_city"
                                name="agent_city"
                                type="text"
                                label="City"
                                value={this.state.agent_city}
                                inputProps={{ maxLength: 32 }}
                                onChange={this.handleChange}
                                helperText=""
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentCity === "error"}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <MutextField
                                required
                                id="agent_state"
                                name="agent_state"
                                type="text"
                                label="State"
                                value={this.state.agent_state}
                                inputProps={{ maxLength: 32 }}
                                onChange={this.handleChange}
                                helperText=""
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentState === "error"}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <MutextField
                                required
                                id="agent_zip"
                                name="agent_zip"
                                type="text"
                                label="Zip / Postal Code"
                                value={this.state.agent_zip}
                                inputProps={{ maxLength: 8 }}
                                onChange={this.handleChange}
                                helperText=""
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentZip === "error"}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <Select
                                id="agent_country"
                                className="customCountrySelect-addAgent"
                                name="agent_country"
                                label="country"
                                options={this.countryOptions}
                                value={{
                                  label: this.state.agent_country,
                                  value: this.state.agent_country,
                                }}
                                onChange={this.handleChangeCountry}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: 9,
                                  }),
                                }}
                                placeholder="Select Country"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_password"
                                name="agent_password"
                                type="password"
                                label="Password"
                                value={this.state.agent_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="c_password"
                                name="c_password"
                                type="password"
                                label="Confirm Password"
                                value={this.state.c_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="sharelink_limit"
                                name="sharelink_limit"
                                type="text"
                                label="Sharelink Limit"
                                inputProps={{ maxLength: 2 }}
                                value={this.state.sharelink_limit}
                                onChange={this.handleChange}
                                helperText="Number of share links channel partner can add."
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentSharelink === "error"}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                id="agent_isVerified"
                                name="agent_isVerified"
                                select
                                label="Verify Account"
                                value={this.state.agent_isVerified}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="e.g Yes or No"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="status1" value="Yes">
                                  Yes
                                </option>
                                <option key="status0" value="No">
                                  No
                                </option>
                              </MutextField>
                            </div>
                            <div className="form-group col-md-6">
                              <div
                                class="custom-control custom-control-lg custom-checkbox"
                                style={{ padding: 0, marginLeft: "3rem" }}
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck2"
                                  onChange={() => {
                                    const isChecked =
                                      !this.state.notifyCheckBox;
                                    this.setState({
                                      notifyCheckBox: isChecked,
                                    });
                                    console.log(
                                      "Notify Checkbox Checked:",
                                      isChecked
                                    );
                                  }}
                                />

                                <label
                                  class="custom-control-label"
                                  for="customCheck2"
                                >
                                  Notify Channel Partner
                                </label>
                              </div>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <hr />
                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalFormDetailService"
              >
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-add-c"></em> Assign
                          Services To Agent{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg text-left">
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            <div
                              id="accordion-2"
                              className="accordion accordion-s3"
                            >
                              {this.state.didArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head"
                                    data-toggle="collapse"
                                    data-target="#accordion-did"
                                  >
                                    <h6 className="title">DID Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse show"
                                    id="accordion-did"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      <div className="nk-block">
                                        <ul className="sp-pdl-list">
                                          {this.state.didArray.map(
                                            (didservices) => (
                                              <li className="sp-pdl-item">
                                                <div className="sp-pdl-desc">
                                                  <div className="sp-pdl-img">
                                                    <FormControlLabel
                                                      control={
                                                        <Radio
                                                          checked={
                                                            didservices[0] ==
                                                            this.state
                                                              .did_service
                                                          }
                                                          onChange={
                                                            this
                                                              .handleChangeRadio
                                                          }
                                                          name="did_service"
                                                          id={didservices[0]}
                                                          value={
                                                            this.state
                                                              .did_service
                                                          }
                                                          color="primary"
                                                        />
                                                      }
                                                      label=""
                                                    />
                                                  </div>
                                                  <div className="sp-pdl-info">
                                                    <h6 className="sp-pdl-title">
                                                      <span className="sp-pdl-name">
                                                        {didservices[1]}
                                                      </span>{" "}
                                                      {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                    </h6>
                                                    <div className="sp-pdl-meta">
                                                      <span className="version">
                                                        <span className="text-soft">
                                                          NRC:{" "}
                                                        </span>{" "}
                                                        <span>
                                                          ${didservices[3]}
                                                        </span>
                                                      </span>
                                                      <span className="release">
                                                        <span className="text-soft">
                                                          MRC:{" "}
                                                        </span>{" "}
                                                        <span>
                                                          ${didservices[4]}
                                                        </span>
                                                      </span>

                                                      <span className="release">
                                                        <span className="text-soft">
                                                          Rate Per Min:{" "}
                                                        </span>{" "}
                                                        <span>
                                                          ${didservices[6]}
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.tfnArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-tfn"
                                  >
                                    <h6 className="title">TFN Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-tfn"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.tfnArray.map(
                                        (tfnservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.tfn_service}
                                                  name="tfn_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {tfnservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      NRC:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${tfnservices[3]}
                                                    </span>
                                                  </span>
                                                  <span className="release">
                                                    <span className="text-soft">
                                                      MRC:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${tfnservices[4]}
                                                    </span>
                                                  </span>

                                                  <span className="release">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${tfnservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.termArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-term"
                                  >
                                    <h6 className="title">
                                      Termination Services
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-term"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.termArray.map(
                                        (termservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={
                                                    this.state.term_service
                                                  }
                                                  name="term_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {termservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${termservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.ivrArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-ivr"
                                  >
                                    <h6 className="title">IVR Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-ivr"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.ivrArray.map(
                                        (ivrservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.ivr_service}
                                                  name="ivr_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {ivrservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${ivrservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.liveagentArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-liveagent"
                                  >
                                    <h6 className="title">
                                      Live Channel Partner Services
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-liveagent"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.liveagentArray.map(
                                        (liveagentservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.ivr_service}
                                                  name="ivr_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {liveagentservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      Rate Per Min:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${liveagentservices[6]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.smsArray !== "" ? (
                                <div className="accordion-item">
                                  <a
                                    href="#"
                                    className="accordion-head collapsed"
                                    data-toggle="collapse"
                                    data-target="#accordion-sms"
                                  >
                                    <h6 className="title">SMS Services</h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className="accordion-body collapse"
                                    id="accordion-sms"
                                    data-parent="#accordion-2"
                                  >
                                    <div className="accordion-inner">
                                      {this.state.smsArray.map(
                                        (smsservices) => (
                                          <li className="sp-pdl-item">
                                            <div className="sp-pdl-desc">
                                              <div className="sp-pdl-img">
                                                <Radio
                                                  checked=""
                                                  onChange={
                                                    this.handleChangeRadio
                                                  }
                                                  value={this.state.tfn_service}
                                                  name="tfn_service"
                                                  inputProps={{
                                                    "aria-label": "A",
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <div className="sp-pdl-info">
                                                <h6 className="sp-pdl-title">
                                                  <span className="sp-pdl-name">
                                                    {smsservices[1]}
                                                  </span>{" "}
                                                  {/*<span className="badge badge-dim badge-primary badge-pill">
                                                        New
                                                    </span>*/}
                                                </h6>
                                                <div className="sp-pdl-meta">
                                                  <span className="version">
                                                    <span className="text-soft">
                                                      SMS In:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${smsservices[10]}
                                                    </span>
                                                  </span>
                                                  <span className="release">
                                                    <span className="text-soft">
                                                      SMS Out:{" "}
                                                    </span>{" "}
                                                    <span>
                                                      ${smsservices[11]}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            {/*{this.state.didArray !== "" ? (
                              <div className="col-md-12 col-lg-12 col-xxl-12">
                                <MutextField
                                  id="did_service"
                                  name="did_service"
                                  select
                                  label="Select DID Service"
                                  value={this.state.did_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the DID service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.didArray.map((didservices) => (
                                    <option value={didservices[0]}>
                                      {didservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.tfnArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="tfn_service"
                                  name="tfn_service"
                                  select
                                  label="Select TFN Service"
                                  value={this.state.tfn_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the TFN service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.tfnArray.map((tfnservices) => (
                                    <option value={tfnservices[0]}>
                                      {tfnservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.termArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="term_service"
                                  name="term_service"
                                  select
                                  label="Select Termination Rate Service"
                                  value={this.state.term_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the termination rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.termArray.map((termservices) => (
                                    <option value={termservices[0]}>
                                      {termservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.ivrArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="ivr_service"
                                  name="ivr_service"
                                  select
                                  label="Select IVR Rate Service"
                                  value={this.state.ivr_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the IVR rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.ivrArray.map((ivrservices) => (
                                    <option value={ivrservices[0]}>
                                      {ivrservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.liveagentArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="liveagent_service"
                                  name="liveagent_service"
                                  select
                                  label="Select Live Agent Rate Service"
                                  value={this.state.liveagent_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the Live Agent rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.liveagentArray.map(
                                    (liveagentservices) => (
                                      <option value={liveagentservices[0]}>
                                        {liveagentservices[1]}
                                      </option>
                                    )
                                  )}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.smsArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="sms_service"
                                  name="sms_service"
                                  select
                                  label="Select SMS Rate Service"
                                  value={this.state.sms_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the SMS rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.smsArray.map((smsservices) => (
                                    <option value={smsservices[0]}>
                                      {smsservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}*/}
                          </div>
                        </div>
                        <div className="nk-modal-action  text-right">
                          {this.state.emptyService == 0 ? (
                            <a
                              style={{ cursor: "pointer" }}
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.formSaveServices();
                              }}
                              disabled={this.state.disabled}
                            >
                              Assign Services
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}

              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalFormAgentAgreements"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em>Agreements{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#tabItemSigned"
                                >
                                  <em className="icon ni ni-check-circle-cut"></em>
                                  <span>Signed Agreements</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#tabItemUnsigned"
                                >
                                  <em className="icon ni ni-na"></em>
                                  <span>Unsigned Agreements</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                className="tab-pane active"
                                id="tabItemSigned"
                              >
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.isRevokeReasonActive ? (
                                  <div id="revokeReasonItems">
                                    <div className="tb-odr-id">
                                      Reason to revoke
                                    </div>
                                    <div className="card card-bordered card-preview mt-1">
                                      <TextareaAutosize
                                        maxrows={4}
                                        name="revoke_reason"
                                        id="revoke_reason"
                                        aria-label=""
                                        placeholder=""
                                        value={this.state.revoke_reason}
                                        helperText="Reason to revoke this agreement"
                                        onChange={this.handleRevokeValueChange}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          borderColor: "rgba(0, 0, 0, 0.125)",
                                          borderRadius: "4px",
                                        }}
                                        variant="outlined"
                                      />
                                    </div>
                                    <div className="form-group text-right mt-2 mb-2">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          this.handleAgentAgreementRevokeSubmit(
                                            this.state.revokeAgreementData
                                              .agreement_id,
                                            this.state.revokeAgreementData
                                              .agreement_accountno,
                                            this.state.agent_id,
                                            this.state.revoke_reason
                                          );
                                        }}
                                        disabled={this.state.disabled}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                ) : null}

                                {this.state.isApproveActive ? (
                                  <div className="row g-gs">
                                    <div className="col-md-12 mb-3">
                                      <div class="card card-bordered">
                                        <div class="card-inner">
                                          <h5 class="card-title">
                                            Approve Agreement
                                          </h5>

                                          <p class="card-text">
                                            Are you sure you want to approve
                                            this agreement?
                                          </p>

                                          <div className="col-md-12">
                                            <div className="form-group text-right">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.setState({
                                                    isApproveActive: false,
                                                    approve_agreement_accountno:
                                                      "",
                                                    approve_agreement_id: "",
                                                  })
                                                }
                                                className="btn btn-light"
                                                disabled={this.state.disabled}
                                                style={{ marginRight: "15px" }}
                                              >
                                                No
                                              </button>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.approveAgreement(
                                                    this.state.agent_id
                                                  )
                                                }
                                                className="btn btn-success"
                                                disabled={this.state.disabled}
                                                title=""
                                              >
                                                Yes
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card card-bordered card-preview">
                                  <table className="table table-orders">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th>
                                          <span className="tb-odr-id">
                                            Agreement Name
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-date d-none d-md-inline-block">
                                            Signed Date
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-total">
                                            Status
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-total">
                                            Approved
                                          </span>
                                        </th>
                                        <th>
                                          <span className="tb-odr-status d-none d-md-inline-block">
                                            Download
                                          </span>
                                        </th>
                                        <th className="tb-odr-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.signedData ? (
                                        <>
                                          {this.state.signedData.map(
                                            (signed, index) => (
                                              <tr
                                                className="tb-odr-item"
                                                key={`sign${index}`}
                                              >
                                                <td>
                                                  <span className="tb-odr-id">
                                                    {signed.agreement_title}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-date">
                                                    {this.format_date(
                                                      signed.signed_date
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-status">
                                                    <span className="badge badge-outline-success">
                                                      Signed
                                                    </span>
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="tb-odr-status">
                                                    {signed.approved ===
                                                    "Yes" ? (
                                                      <span className="badge badge-outline-success">
                                                        Yes
                                                      </span>
                                                    ) : (
                                                      <span className="badge badge-outline-danger">
                                                        No
                                                      </span>
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  <a
                                                    onClick={() =>
                                                      this.downloadPdf(
                                                        signed.pdf_path,
                                                        signed.agreement_title
                                                      )
                                                    }
                                                    title="Download Agreement"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <span className="badge badge-outline-primary">
                                                      <em className="icon ni ni-download"></em>
                                                    </span>
                                                  </a>
                                                </td>
                                                <td className="tb-odr-action">
                                                  <div className="dropdown">
                                                    <a
                                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                      data-toggle="dropdown"
                                                      data-offset="-8,0"
                                                    >
                                                      <em className="icon ni ni-more-h"></em>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                      <ul className="link-list-plain">
                                                        {!signed.approved ||
                                                        signed.approved ===
                                                          "No" ? (
                                                          <li>
                                                            <a
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              className="text-success"
                                                              onClick={() =>
                                                                this.setState({
                                                                  isApproveActive: true,
                                                                  approve_agreement_id:
                                                                    signed.agreement_id,
                                                                  approve_agreement_accountno:
                                                                    signed.agreement_accountno,
                                                                })
                                                              }
                                                            >
                                                              Approve
                                                            </a>
                                                          </li>
                                                        ) : null}
                                                        <li>
                                                          <a
                                                            href="#"
                                                            onClick={() =>
                                                              this.revokeAgreement(
                                                                signed.agreement_id,
                                                                signed.agreement_accountno
                                                              )
                                                            }
                                                            className="text-primary"
                                                          >
                                                            Revoke
                                                          </a>
                                                        </li>
                                                        {/*<li>
                                                          <a
                                                            href="#"
                                                            className="text-danger"
                                                          >
                                                            Send Email
                                                          </a>
                                            </li>*/}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="tab-pane" id="tabItemUnsigned">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="card card-bordered card-preview">
                                  <table className="table table-orders">
                                    <thead className="tb-odr-head">
                                      <tr className="tb-odr-item">
                                        <th>
                                          <span className="tb-odr-id">
                                            Agreement Name
                                          </span>
                                        </th>

                                        <th>
                                          <span className="tb-odr-total">
                                            Status
                                          </span>
                                        </th>

                                        <th className="tb-odr-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tb-odr-body">
                                      {this.state.unsignedData ? (
                                        <>
                                          {this.state.unsignedData.map(
                                            (unsigned, index) => (
                                              <tr
                                                className="tb-odr-item"
                                                key={`unsign${index}`}
                                              >
                                                <td>
                                                  <span className="tb-odr-id">
                                                    {unsigned.agreement_title}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="tb-odr-status">
                                                    <span className="badge badge-outline-warning">
                                                      Unsigned
                                                    </span>
                                                  </span>
                                                </td>

                                                <td className="tb-odr-action">
                                                  {this.state.isEmailSending &&
                                                  this.state
                                                    .unsigned_email_agreement_id ===
                                                    unsigned.agreement_id ? (
                                                    <div
                                                      className="spinner-border spinner-border-sm"
                                                      role="status"
                                                    >
                                                      <span className="sr-only">
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="dropdown">
                                                      <a
                                                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                        data-toggle="dropdown"
                                                        data-offset="-8,0"
                                                      >
                                                        <em className="icon ni ni-more-h"></em>
                                                      </a>
                                                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                        <ul className="link-list-plain">
                                                          {/*<li>
                                                          <a
                                                            href="#"
                                                            className="text-primary"
                                                          >
                                                            Revoke
                                                          </a>
                                            </li>*/}
                                                          <li>
                                                            <a
                                                              href="#"
                                                              className="text-danger"
                                                              onClick={() =>
                                                                this.sendEmailUnsignedAgreement(
                                                                  unsigned.agreement_id,
                                                                  unsigned.agreement_accountno,
                                                                  this.state
                                                                    .agent_id
                                                                )
                                                              }
                                                            >
                                                              Send Email
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ) : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-modal-action  text-right">
                          {this.state.emptyService == 0 ? (
                            <a
                              href="#"
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.formSaveServices();
                              }}
                              disabled={this.state.disabled}
                            >
                              Assign Services
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Agreement MODAL End */}

              {/*** AGENT COMMISSION ***/}

              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAgentCommission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-report-profit"></em>{" "}
                          Commission Statistics of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="row g-gs">
                          <div className="col-md-12">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#tabItem5"
                                >
                                  <em className="icon ni ni-report-profit"></em>
                                  <span>Statistics</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  onClick={() => this.get_commission_settings()}
                                  href="#tabItem6"
                                >
                                  <em className="icon ni ni-setting"></em>
                                  <span>Settings</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="tabItem5">
                                <div className="col-lg-6 col-xxl-12">
                                  <div className="row g-gs">
                                    <div className="col-sm-6 col-lg-12 col-xxl-6">
                                      <div className="card card-bordered">
                                        <div className="card-inner">
                                          {this.state.show_total_sales ===
                                          true ? (
                                            UpworkJobLoader()
                                          ) : (
                                            <>
                                              <div className="card-title-group align-start mb-2">
                                                <div className="card-title">
                                                  <h6 className="title">
                                                    Total Sales
                                                  </h6>
                                                </div>
                                                <div className="card-tools"></div>
                                              </div>
                                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                <div className="nk-sale-data">
                                                  <span className="amount">
                                                    {formatAsCurrency(
                                                      this.state
                                                        .agent_total_sales
                                                    )}
                                                  </span>
                                                  <span className="sub-title">
                                                    <span className="text-primary">
                                                      Sales Report
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-12 col-xxl-6">
                                      <div className="card card-bordered">
                                        <div className="card-inner">
                                          {this.state.show_total_commision ===
                                          true ? (
                                            UpworkJobLoader()
                                          ) : (
                                            <>
                                              <div className="card-title-group align-start mb-2">
                                                <div className="card-title">
                                                  <h6 className="title">
                                                    Total Commission
                                                  </h6>
                                                </div>
                                                <div className="card-tools"></div>
                                              </div>
                                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                <div className="nk-sale-data">
                                                  <span className="amount">
                                                    {formatAsCurrency(
                                                      this.state
                                                        .agent_total_commision
                                                    )}
                                                  </span>
                                                  <span className="sub-title">
                                                    <span className="text-primary">
                                                      Commission Report
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-12 col-xxl-6">
                                      <div className="card card-bordered">
                                        <div className="card-inner">
                                          {this.state.show_paid_commision ===
                                          true ? (
                                            UpworkJobLoader()
                                          ) : (
                                            <>
                                              <div className="card-title-group align-start mb-2">
                                                <div className="card-title">
                                                  <h6 className="title">
                                                    Paid
                                                  </h6>
                                                </div>
                                                <div className="card-tools"></div>
                                              </div>
                                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                <div className="nk-sale-data">
                                                  <span className="amount">
                                                    {formatAsCurrency(
                                                      this.state
                                                        .agent_paid_commision
                                                    )}
                                                  </span>
                                                  <span className="sub-title">
                                                    <span className="text-primary">
                                                      Paid Report
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-12 col-xxl-6">
                                      <div className="card card-bordered">
                                        <div className="card-inner">
                                          {this.state.show_pending_commision ===
                                          true ? (
                                            UpworkJobLoader()
                                          ) : (
                                            <>
                                              <div className="card-title-group align-start mb-2">
                                                <div className="card-title">
                                                  <h6 className="title">
                                                    Pending
                                                  </h6>
                                                </div>
                                                <div className="card-tools"></div>
                                              </div>
                                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                <div className="nk-sale-data">
                                                  <span className="amount">
                                                    {formatAsCurrency(
                                                      this.state
                                                        .agent_pending_commision
                                                    )}
                                                  </span>
                                                  <span className="sub-title">
                                                    <span className="text-primary">
                                                      {this.state
                                                        .agent_pending_commision &&
                                                      this.state
                                                        .agent_pending_commision >
                                                        0 ? (
                                                        <a
                                                          onClick={() => {
                                                            this.setState({
                                                              pay_now_form: true,
                                                              fundErrorMessage:
                                                                "",
                                                              fundSuccessMessage:
                                                                "",
                                                            });
                                                          }}
                                                          className="text-primary"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          Pay Now
                                                        </a>
                                                      ) : (
                                                        <> </>
                                                      )}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.pay_now_form === true ? (
                                      <div className="col-md-12 mt-4">
                                        <div className="row g-gs border-top">
                                          {this.state.fundErrorMessage !==
                                          "" ? (
                                            <div className="col-md-12 mb-2">
                                              <div class="alert alert-pro alert-danger">
                                                <div class="alert-text">
                                                  <h6>Error</h6>
                                                  <p>
                                                    {
                                                      this.state
                                                        .fundErrorMessage
                                                    }
                                                  </p>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          ) : null}
                                          {this.state.fundSuccessMessage !==
                                          "" ? (
                                            <div className="col-md-12 mb-2">
                                              <div class="alert alert-pro alert-success">
                                                <div class="alert-text">
                                                  <h6>Success</h6>
                                                  <p>
                                                    {
                                                      this.state
                                                        .fundSuccessMessage
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          <div className="col-md-8">
                                            <div className="form-group">
                                              <div className="form-label-group">
                                                <label className="form-label">
                                                  <Tooltip
                                                    title="Enter the amount to deposit to the channel partner account."
                                                    placement="right"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>{" "}
                                                  Enter Amount{" "}
                                                  <span className="text-danger"></span>
                                                </label>
                                              </div>
                                              <div className="form-control-group">
                                                <MutextField
                                                  id="payment_amount"
                                                  name="payment_amount"
                                                  type="text"
                                                  label=""
                                                  value={
                                                    this.state.payment_amount
                                                  }
                                                  onChange={this.handleChange}
                                                  onKeyPress={() => {
                                                    this.checkNumber();
                                                  }}
                                                  inputProps={{
                                                    maxLength: 9, // Set the maximum length to 10 characters
                                                  }}
                                                  variant="outlined"
                                                  fullWidth
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div class="form-action mt-5">
                                              <ul class="form-btn-group">
                                                <li class="form-btn-primary">
                                                  {this.state
                                                    .add_funds_button ===
                                                  false ? (
                                                    <a
                                                      onClick={() =>
                                                        this.addFundsInAgentAccount()
                                                      }
                                                      class="btn btn-primary text-white"
                                                    >
                                                      <em class="icon ni ni-sign-dollar"></em>{" "}
                                                      Add Funds
                                                    </a>
                                                  ) : (
                                                    <button
                                                      className="btn btn-primary"
                                                      disabled
                                                    >
                                                      <em class="icon ni ni-sign-dollar"></em>{" "}
                                                      Add Funds
                                                      <div
                                                        className="spinner-grow spinner-grow-sm"
                                                        role="status"
                                                      >
                                                        <span className="sr-only">
                                                          Loading...
                                                        </span>
                                                      </div>
                                                    </button>
                                                  )}
                                                </li>
                                                {this.state.add_funds_button ===
                                                false ? (
                                                  <li class="">
                                                    <a
                                                      onClick={() => {
                                                        this.setState({
                                                          pay_now_form: false,
                                                          pay_now_button: false,
                                                          payment_amount: "",
                                                          fundErrorMessage: "",
                                                          fundSuccessMessage:
                                                            "",
                                                        });
                                                      }}
                                                      class="btn btn-dim btn-outline-light"
                                                    >
                                                      Cancel
                                                    </a>
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane" id="tabItem6">
                                <div className="row g-gs">
                                  <div className="col-lg-12 col-xxl-12 col-sm-12">
                                    <h4 className="title nk-block-title">
                                      Commission Settings
                                    </h4>
                                    {this.state.commissionErrorMessage !==
                                    "" ? (
                                      <div className="col-md-12">
                                        <div
                                          className="example-alert"
                                          style={{ marginBottom: "15px" }}
                                        >
                                          <div className="alert alert-pro alert-danger">
                                            <div className="alert-text">
                                              <h4>
                                                {
                                                  this.state
                                                    .commissionErrorTitle
                                                }
                                              </h4>
                                              <p>
                                                {
                                                  this.state
                                                    .commissionErrorMessage
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.commissionSuccessMessage !==
                                    "" ? (
                                      <div className="col-md-12">
                                        <div
                                          className="example-alert"
                                          style={{ marginBottom: "15px" }}
                                        >
                                          <div className="alert alert-pro alert-success">
                                            <div className="alert-text">
                                              <h4>Success</h4>
                                              <p>
                                                {
                                                  this.state
                                                    .commissionSuccessMessage
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Set the default value of how the commission will be applied."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Apply Commission{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>

                                      <div className="form-control-wrap ">
                                        <div className="form-control-select">
                                          <select
                                            className="form-control form-control-lg"
                                            id="apply_commission"
                                            name="apply_commission"
                                            select
                                            label=""
                                            value={this.state.apply_commission}
                                            onChange={this.handleChange}
                                          >
                                            <option
                                              key="setac1"
                                              value="agent_account"
                                            >
                                              Channel Partner Account
                                            </option>
                                            <option
                                              key="setac2"
                                              value="product_services"
                                            >
                                              Product & Services
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xxl-6 col-sm-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="default-01"
                                      >
                                        Commission Value{" "}
                                        {this.state.commission_value_type ===
                                        "percentage"
                                          ? "(%)"
                                          : ""}
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control form-control-lg"
                                          id="commission_value"
                                          name="commission_value"
                                          label=""
                                          value={this.state.commission_value}
                                          onChange={this.handleChange}
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          maxLength={9}
                                        />
                                      </div>
                                      <div className="form-note"></div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xxl-6 col-sm-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="commission_type"
                                      >
                                        <Tooltip
                                          title="Billed Commission: Paid when the sale is invoiced or billed to the customer, regardless of whether the payment has been received. Collected Commission: Paid only when the payment from the customer is collected. This can be more common in industries where payment collection might be a prolonged process."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Commission Payment Terms
                                      </label>
                                      <div className="form-control-wrap ">
                                        <div className="form-control-select">
                                          <select
                                            className="form-control form-control-lg"
                                            id="commission_type"
                                            name="commission_type"
                                            select
                                            label="Choose Payment Terms"
                                            value={this.state.commission_type}
                                            onChange={this.handleChange}
                                          >
                                            <option
                                              key="setst1"
                                              value="paid_on_billed"
                                            >
                                              Paid On Billed
                                            </option>
                                            <option
                                              key="setst2"
                                              value="paid_on_collected"
                                            >
                                              Paid On Collected
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-note"></div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xxl-6 col-sm-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="commission_value_type"
                                      >
                                        Commission Type
                                      </label>
                                      <div className="form-control-wrap ">
                                        <div className="form-control-select">
                                          <select
                                            className="form-control form-control-lg"
                                            id="commission_value_type"
                                            name="commission_value_type"
                                            select
                                            label="Choose Commission Type"
                                            value={
                                              this.state.commission_value_type
                                            }
                                            onChange={this.handleChange}
                                          >
                                            <option key="setst33" value="fixed">
                                              Fixed
                                            </option>
                                            <option
                                              key="setst23"
                                              value="percentage"
                                            >
                                              Percentage
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-note">
                                        Channel partner receive a fixed or
                                        percentage based on the profit margin.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-xxl-12 col-sm-12 mt-4  text-right">
                                    <div className="form-group">
                                      {this.state.commission_button ===
                                      false ? (
                                        <button
                                          type="button"
                                          className="btn btn-lg btn-primary"
                                          onClick={() =>
                                            this.update_commission_settings()
                                          }
                                          disabled={this.state.disabled}
                                        >
                                          Save Settings
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lg btn-primary"
                                          style={{ marginRight: "15px" }}
                                          disabled
                                        >
                                          <em className="icon ni ni-swap"></em>{" "}
                                          Save Settings
                                          <div
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="preview-hr" />
                    <div className="row g-4"></div>
                  </div>
                </div>
              </div>

              {/*** AGENT COMMISSION ***/}

              {/* SUB AGENT MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormSubAgents">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em> Sub Channel
                          Partner{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnBundleServices}
                              tableData={this.state.subAgentsData}
                              title="All Sub Channel Partners"
                              style={{ border: "0px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SUB AGENT MODAL END */}

              {/* PAGE TITLE END */}
              <div className="nk-block">
                {/* Table 5th Col Start */}
                <div className="row g-gs">
                  <div className="col-xxl-12">
                  <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">
                            <ul class="nav nav-tabs">
                              <li class="nav-item ">
                                <a
                                  class={`nav-link ${
                                    this.state.activeTab === "tabItem6"
                                      ? "active"
                                      : ""
                                  }`}
                                  data-toggle="tab"
                                  href="#tabItem6"
                                  onClick={() =>
                                    this.handleTabClick("tabItem6")
                                  }
                                >
                                  <em class="icon ni ni-users"></em>
                                  <span>{localization.agentsListing.allCPTab}</span>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${
                                    this.state.activeTab === "tabItem7"
                                      ? "active"
                                      : ""
                                  }`}
                                  data-toggle="tab"
                                  href="#tabItem7"
                                  onClick={() =>
                                    this.handleTabClick("tabItem7")
                                  }
                                >
                                  <em class="icon ni ni-user-list"></em>
                                  <span>{localization.agentsListing.invitationTab}</span>
                                </a>
                              </li>
                            </ul>
                            <div class="tab-content" style={{ marginTop: 0 }}>
                              <div
                                class={`tab-pane ${
                                  this.state.activeTab === "tabItem6"
                                    ? "active"
                                    : ""
                                }`}
                                id="tabItem6"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={this.state.columnServices}
                                        log_type={log_type}
                                        accountnoFromParams={accountno}
                                        tableData={this.state.tableData}
                                        from="agents"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                class={`tab-pane ${
                                  this.state.activeTab === "tabItem7"
                                    ? "active"
                                    : ""
                                }`}
                                id="tabItem7"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={
                                          this.state
                                            .columnServices_invitedAgents
                                        }
                                        log_type={log_type}
                                        accountnoFromParams={accountno}
                                        tableData={
                                          this.state.tableData_invitedAgents
                                        }
                                        from="agents"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
              {/* Activation Modal */}
              <div className="modal fade" tabIndex="-1" id="modalAlertResend">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a href="#" className="close" data-dismiss="modal">
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 className="nk-modal-title">Sending!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Congratulations!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 className="nk-modal-title">Oops!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.already_activated === true ? (
                          <>
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Congratulations!</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                Account is already verified. It may be
                                deactivated for some other reason by the admin.
                              </div>
                              <span className="sub-text-sm"></span>
                            </div>
                            <div className="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="modal-footer bg-lighter">
                      <div className="text-center w-100">
                        <a
                          href="#"
                          className="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Activation Modal */}
              {/* Role Assignment Modal START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAgentAssignRole"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-alt"></em>Channel Partner Menu{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.rolesAgentName}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.agentRoleLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.errorMessageAgentRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageAgentRole}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageAgentRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageAgentRole}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="card card-bordered card-preview">
                                <table className="table table-sm">
                                  <thead className="tb-odr-head">
                                    <tr className="tb-odr-item">
                                      <th
                                        width="35%"
                                        style={{
                                          borderRight: "1px solid #dbdfea",
                                        }}
                                      >
                                        <span className="tb-odr-action">
                                          Main Menu
                                        </span>
                                      </th>
                                      <th width="65%">
                                        <span className="tb-odr-action">
                                          Sub Menu
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="tb-odr-body">
                                    {this.state.allRoleData &&
                                    this.state.allRoleData.length > 0 ? (
                                      <>
                                        {this.state.allRoleData.map(
                                          (role, index) => (
                                            <tr
                                              className=""
                                              key={`role${index}`}
                                            >
                                              <td
                                                style={{
                                                  borderRight:
                                                    "1px solid #dbdfea",
                                                }}
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={this.state.allRoleData.some(
                                                        (item) =>
                                                          item.alt ===
                                                            role.alt &&
                                                          item.isChecked
                                                      )}
                                                      onChange={
                                                        this
                                                          .changedAllRolesRights
                                                      }
                                                      disabled={
                                                        role.seqno === 1 &&
                                                        this.state.assignRoleData.some(
                                                          (subRole) =>
                                                            role.alt ===
                                                            subRole.alt
                                                        )
                                                      }
                                                      name={role?.id?.toString()}
                                                      color="primary"
                                                    />
                                                  }
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                  label={role.alt}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  paddingLeft: "16px",
                                                }}
                                              >
                                                {this.state.allSubRoleData
                                                  .filter((subRole) => {
                                                    return (
                                                      subRole.role_id ===
                                                      role.id
                                                    );
                                                  })
                                                  .map((subRole, subIndex) => (
                                                    <div
                                                      key={`subRole${subIndex}`}
                                                    >
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={this.state.allSubRoleData.some(
                                                              (item) =>
                                                                item.alt ===
                                                                  subRole.alt &&
                                                                item.isChecked
                                                            )}
                                                            onChange={
                                                              this
                                                                .changedAllSubRolesRights
                                                            }
                                                            name={subRole?.tabdetailid?.toString()}
                                                            color="primary"
                                                          />
                                                        }
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        label={subRole.alt}
                                                      />
                                                    </div>
                                                  ))}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                              <div className="nk-modal-action  text-right">
                                <a
                                  href="#"
                                  className="btn btn-lg btn-mw btn-primary "
                                  onClick={() => {
                                    this.handleSubmitRoleCheckbox(
                                      this.state.checkedName,
                                      this.state.checkedEmail
                                    );
                                  }}
                                  disabled={this.state.disableRoleBtn}
                                >
                                  Save Changes
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Role Assignment Modal End */}

              {/* Role Sub Assignment Modal START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalSubAgentAssignRole"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-alt"></em>Sub Channel
                          Partner Menu{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.rolesAgentName}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.agentRoleLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.errorMessageAgentRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageAgentRole}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageAgentRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageAgentRole}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="card card-bordered card-preview">
                                <table className="table table-orders">
                                  <thead className="tb-odr-head">
                                    <tr className="tb-odr-item">
                                      <th
                                        width="35%"
                                        style={{
                                          borderRight: "1px solid #dbdfea",
                                        }}
                                      >
                                        <span className="tb-odr-action">
                                          Main Menu
                                        </span>
                                      </th>
                                      <th width="65%">
                                        <span className="tb-odr-action">
                                          Sub Menu
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="tb-odr-body">
                                    {this.state.allRoleData &&
                                    this.state.allRoleData.length > 0 ? (
                                      <>
                                        {this.state.allRoleData.map(
                                          (role, index) => (
                                            <tr
                                              className="tb-odr-item"
                                              key={`role${index}`}
                                            >
                                              <td
                                                style={{
                                                  borderRight:
                                                    "1px solid #dbdfea",
                                                }}
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={this.state.allRoleData.some(
                                                        (item) =>
                                                          item.alt ===
                                                            role.alt &&
                                                          item.isChecked
                                                      )}
                                                      onChange={
                                                        this
                                                          .changedAllRolesRights
                                                      }
                                                      disabled={
                                                        role.seqno === 1 &&
                                                        this.state.assignRoleData.some(
                                                          (subRole) =>
                                                            role.alt ===
                                                            subRole.alt
                                                        )
                                                      }
                                                      name={role?.id?.toString()}
                                                      color="primary"
                                                    />
                                                  }
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                  label={role.alt}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  paddingLeft: "16px",
                                                }}
                                              >
                                                {this.state.allSubRoleData
                                                  .filter((subRole) => {
                                                    return (
                                                      subRole.role_id ===
                                                      role.id
                                                    );
                                                  })
                                                  .map((subRole, subIndex) => (
                                                    <div
                                                      key={`subRole${subIndex}`}
                                                    >
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={this.state.allSubRoleData.some(
                                                              (item) =>
                                                                item.alt ===
                                                                  subRole.alt &&
                                                                item.isChecked
                                                            )}
                                                            onChange={
                                                              this
                                                                .changedAllSubRolesRights
                                                            }
                                                            name={subRole?.tabdetailid?.toString()}
                                                            color="primary"
                                                          />
                                                        }
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        label={subRole.alt}
                                                      />
                                                    </div>
                                                  ))}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                              <div className="nk-modal-action  text-right">
                                <a
                                  href="#"
                                  className="btn btn-lg btn-mw btn-primary "
                                  onClick={() => {
                                    this.handleSubmitRoleCheckbox(
                                      this.state.checkedName,
                                      this.state.checkedEmail
                                    );
                                  }}
                                  disabled={this.state.disableRoleBtn}
                                >
                                  Channel Partner Menu
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Role Sub Assignment Modal End */}
              {/* New Agent Modal Start */}
              <div className="modal fade" tabindex="-1" id="modalAddNewAgent">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em
                          className="icon ni ni-users"
                          style={{ marginRight: "15px" }}
                        ></em>{" "}
                        Add New Channel Partner
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <div className="row g-4">
                          {this.state.agentErrorMessage !== "" ? (
                            <div className="col-md-12 mb-3">
                              <div
                                className="example-alert example-alert-revoke"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{this.state.agentErrorMessage}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.agentSuccessMessage !== "" ? (
                            <div className="col-md-12 mb-3">
                              <div
                                className="example-alert example-alert-revoke"
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.agentSuccessMessage}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-control-group">
                                <MutextField
                                  required
                                  id="agent_name"
                                  name="agent_name"
                                  type="text"
                                  label="Full Name"
                                  defaultValue={this.state.agent_name}
                                  inputProps={{ maxLength: 64 }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^a-zA-Z0-9 ]/gi,
                                      ""
                                    );
                                  }}
                                  helperText="Full name of the invite recipient."
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-control-group">
                                <MutextField
                                  required
                                  id="agent_email"
                                  name="agent_email"
                                  type="text"
                                  label="Email Address"
                                  defaultValue={this.state.agent_email}
                                  helperText="Email address of the invite recipient."
                                  inputProps={{ maxLength: 100 }}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="button"
                              onClick={() => this.saveNewAgent()}
                              className="btn btn-primary"
                              disabled={this.state.disabled}
                            >
                              <span>Send Invite</span>{" "}
                              <em className="icon ni ni-emails"></em>
                            </button>
                          </div>
                          <div className="col-md-12">
                            <div className="example-alert">
                              <div className="alert alert-light">
                                <ul className="list">
                                  <li>
                                    An email will be dispatched to the provided
                                    email address.
                                  </li>
                                  <li>
                                    This email will contain a secure, tokenized
                                    link for channel partner registration.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* New Agent Modal End */}
              {/* MODAL Delete Agent START */}
              <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideDel();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                        <h4 className="nk-modal-title">
                          Delete Invited Channel Partner?
                        </h4>
                        {this.state.agentInviteErrorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.agentInviteErrorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.agentInviteSuccessMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.agentInviteSuccessMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            Are you sure you want to delete this Invited Channel
                            Partner?
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHideDel();
                            }}
                          >
                            CANCEL
                          </a>
                          {"  "}
                          <button
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-danger"
                            onClick={() => {
                              this.deleteInviteAgent();
                            }}
                            disabled={this.state.disabled}
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL Delete Agent End */}

              {/* Resend Invitation Modal Start */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalresendInvitation"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    {this.state.agentInviteSuccessMessage && (
                      <>
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideResend();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4
                              className="nk-modal-title"
                              style={{ fontSize: "1.3rem" }}
                            >
                              {this.state.agentInviteSuccessMessage}
                            </h4>
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.agentInviteErrorMessage && (
                      <>
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideResend();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4
                              className="nk-modal-title"
                              style={{ fontSize: "1.3rem" }}
                            >
                              {this.state.agentInviteErrorMessage}
                            </h4>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* Resend Invitation Modal Ends */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agents);
