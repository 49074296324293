import React, { Component } from "react";
import RichTextEditorComponent from "./RichTextEditorComponent";

class TestRichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bank_account_details:
        "<p>Account Number: 123456789</p><p>Bank Name: ABC Bank</p><p>Branch: XYZ Branch</p>",
    };
  }

  handleEditorBank = (newContent) => {
    this.setState({ bank_account_details: newContent });
  };

  render() {
    const containerStyle = {
      padding: "20px",
      backgroundColor: "#f4f4f4",
      borderRadius: "5px",
      margin: "20px auto",
    };

    const headerStyle = {
      marginBottom: "10px",
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#333",
    };

    return (
      <div style={containerStyle}>
        <div style={headerStyle}>TestRichTextEditor</div>
        <RichTextEditorComponent
          agreementContent={this.state.bank_account_details}
          onContentChange={this.handleEditorBank}
        />
      </div>
    );
  }
}

export default TestRichTextEditor;
