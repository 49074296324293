import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { forgotEmailSend } from "../../../config/api_calls";
import { ValidateEmail, axiosConfig } from "../../../config/utility";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  COMPANY_NAME,
} from "../../../config/config";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorEmail: "",
      errorMessage: "",
      successMessage: "",
      btnDisable: false,
    };
  }

  async componentDidMount() {}

  formPost = async (e) => {
    e.preventDefault();
    let { email } = this.state;

    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else {
      this.setState({
        errorEmail: "",
        errorMessage: "",
        btnDisable: true,
      });
      let apiCall = await forgotEmailSend(email);
      console.log("status: ", apiCall.data.status);
      console.log("errors: ", apiCall.data.errors);
      console.log("message: ", apiCall.data.message);

      if (apiCall.data.status === 404 || apiCall.data.errors !== "") {
        this.setState({
          errorEmail: "error",
          errorMessage: apiCall.data.errors,
          btnDisable: false,
        });
      } else if (
        apiCall.data.status === 200 &&
        apiCall.data.message === "Success"
      ) {
        this.setState({
          successMessage:
            "Email sent to your account, Please check inbox to complete this process.",
        });
      } else {
        window.location.replace("/error");
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn btn-white btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <a href="/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={LOGO}
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={LOGO}
                    />
                  </a>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Reset password</h5>
                    {this.state.errorMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.errorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.successMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.successMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <br />
                    <div className="nk-block-des">
                      <p>
                        If you forgot your password, well, then we’ll email you
                        instructions to reset your password.
                      </p>
                    </div>
                  </div>
                </div>
                <form
                  method="post"
                  className="forgotForm"
                  onSubmit={this.formPost}
                >
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Email
                      </label>
                      {/* <a className="link link-primary link-sm" href="#">
                        Need Help?
                      </a> */}
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-lg btn-block"
                      style={{
                        backgroundColor: THEME_COLOR,
                        color: THEME_TEXT_COLOR,
                      }}
                      type="submit"
                      disabled={this.state.btnDisable}
                    >
                      Send Reset Link
                    </button>
                  </div>
                </form>
                <div className="form-note-s2 pt-5">
                  <a href="/">
                    <strong>Back to login</strong>
                  </a>
                </div>
              </div>
              <div className="nk-block nk-auth-footer">
                <div className="mt-3">
                  <p>
                    &copy; {new Date().getFullYear()}{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img className="round" src={PROMO_A} alt="" />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>{COMPANY_NAME}</h4>
                    <p>
                      If you forgot your password, well, then we’ll email you
                      instructions to reset your password.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
