import { React } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail, normalize } from "../../../config/utility";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  listSubUsers,
  getSingleUser,
  editSubUser,
  sendSubUserInvite,
  deleteInviteUser,
} from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import dateFormat, { masks } from "dateformat";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";

const auth = new HelperClass();
class SubUsers extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableDataInvite: [],
      editUserData: [],
      c_password: "",
      errorMessage: "",
      errorMessageEdit: "",
      agentId: "",
      successMessage: "",
      successMessageEdit: "",
      eSuccessMessage: "",
      eErrorMessage: "",
      waitMessage: "",
      tableLoader: true,
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: false,
      errorPass: false,
      errorcPass: false,
      errorName: false,
      errorMessage: "",
      errorCompName: false,
      errorTitle: false,
      disabled: false,
      successMessage: "",
      errorConfirm: false,
      disableResendBtn: false,
      phone: "",
      user_id: "",
      account_status: "",
      errorPhone: false,
      agent_id: "",
      serviceData: [],
      signedData: [],
      unsignedData: [],
      isEmailSending: false,
      unsigned_email_agreement_id: 0,
      revoke_reason: "",
      isRevokeReasonActive: false,
      subuser_email: "",
      subuser_name: "",
      subuserErrorMessage: "",
      subuserSuccessMessage: "",
      passwordShown: false,
      confirmPasswordShown: false,
      columnServices: [
        { name: "Title" },
        { name: "Company Name" },
        { name: "Email" },
        { name: "Phone" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setDomain(value, tableMeta)}</div>;
            },
          },
        },

        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.editUserForm(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Sub User"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Sub User</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/sub_users/user_roles/" + value
                                    )
                                  }
                                  title="Add / Edit User Roles"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>User Roles</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnServicesInvite: [
        { name: "ID" },
        { name: "Name" },
        { name: "Email" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[0])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete User</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.resendActication(
                                      tableMeta.rowData[1],
                                      tableMeta.rowData[2]
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Resend Activation"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>Resend Activation</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listSubUsers(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableDataInvite: servicesResponce.data.dataInvite,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteInvitedUser = async () => {
    let user_id = this.state.delete_id;
    const userResponce = await deleteInviteUser(
      auth.getAccount(),
      auth.getToken(),
      user_id
    );

    console.log("deleteUsert: ", userResponce.data);
    //$("#btnloader").show();
    if (
      userResponce.data.status === 403 ||
      userResponce.data.errors === "authentication missing" ||
      userResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      userResponce.data.status === 404 &&
      userResponce.data.message === "fail" &&
      userResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: userResponce.data.note,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      userResponce.data.status === 200 &&
      userResponce.data.message === "success"
    ) {
      //await this.reLoadClients();
      this.setState({
        errorMessage: "",
        successMessage: "Invited User deleted successfully.",
        disabled: false,
      });

      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessage: userResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  editUserForm = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[6];
    const servicesResponce = await getSingleUser(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        user_id: servicesResponce.data.data[0]["id"],
        name: servicesResponce.data.data[0]["name"],
        comp_name: servicesResponce.data.data[0]["comp_name"],
        title: servicesResponce.data.data[0]["title"],
        email: servicesResponce.data.data[0]["email"],
        phone: servicesResponce.data.data[0]["phone"],
        account_status: servicesResponce.data.data[0]["account_status"],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  setNewSubUserSettings = () => {
    this.setState({
      subuserErrorMessage: "",
      subuserSuccessMessage: "",
      subuser_name: "",
      subuser_email: "",
    });

    window.$("#modalAddNewSubUser").modal("show");
  };

  // saveNewSubUser = async () => {
  //   const { subuser_name, subuser_email } = this.state;
  //   this.setState({
  //     subuserErrorMessage: "",
  //     subuserSuccessMessage: "",
  //     disabled: true,
  //   });

  //   if (subuser_name === "") {
  //     this.setState({
  //       subuserErrorMessage: "Please enter the name.",
  //       subuserSuccessMessage: "",
  //       disabled: false,
  //     });
  //   } else if (subuser_email === "") {
  //     this.setState({
  //       subuserErrorMessage: "Please enter the email address.",
  //       subuserSuccessMessage: "",
  //       disabled: false,
  //     });
  //   } else if (ValidateEmail(subuser_email) === false) {
  //     this.setState({
  //       subuserErrorMessage: "Please enter the valid email address.",
  //       subuserSuccessMessage: "",
  //       disabled: false,
  //     });
  //   } else {
  //     const servicesResponce = await sendSubUserInvite(
  //       auth.getAccount(),
  //       auth.getToken(),
  //       subuser_email,
  //       subuser_name
  //     );

  //     console.log("agentsResponce.data.data: ", servicesResponce.data);

  //     if (
  //       servicesResponce.data.status === 403 ||
  //       servicesResponce.data.errors === "authentication missing" ||
  //       servicesResponce.data.errors === "jwt expired"
  //     ) {
  //       auth.logout();
  //     } else if (servicesResponce.data.status === 404) {
  //       this.setState({
  //         subuserErrorMessage:
  //           "There is some error while sending invite to the user.",
  //         subuserSuccessMessage: "",
  //         disabled: false,
  //       });
  //     } else if (
  //       servicesResponce.data.status === 200 &&
  //       servicesResponce.data.message === "success"
  //     ) {
  //       this.setState(
  //         {
  //           subuserErrorMessage: "",
  //           subuserSuccessMessage: "Invite successfully sent to the user.",
  //           disabled: false,
  //         },
  //         () => {
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 3000);
  //         }
  //       );
  //     } else {
  //       this.setState({
  //         subuserErrorMessage:
  //           "There is some error while sending invite to the user.",
  //         subuserSuccessMessage: "",
  //         disabled: false,
  //       });
  //     }
  //   }
  //   setTimeout(() => {
  //     this.setState({
  //       subuserErrorMessage: "",
  //       subuserSuccessMessage: "",
  //     });
  //   }, 4000);
  // };

  saveNewSubUser = async () => {
    const { subuser_name, subuser_email } = this.state;

    this.setState({
      subuserErrorMessage: "",
      subuserSuccessMessage: "",
      disabled: true,
    });

    if (subuser_name === "") {
      this.setState({
        subuserErrorMessage: "Please enter the name.",
        subuserSuccessMessage: "",
        disabled: false,
      });
    } else if (subuser_email === "") {
      this.setState({
        subuserErrorMessage: "Please enter the email address.",
        subuserSuccessMessage: "",
        disabled: false,
      });
    } else if (ValidateEmail(subuser_email) === false) {
      this.setState({
        subuserErrorMessage: "Please enter a valid email address.",
        subuserSuccessMessage: "",
        disabled: false,
      });
    } else {
      const servicesResponce = await sendSubUserInvite(
        auth.getAccount(),
        auth.getToken(),
        subuser_email,
        subuser_name
      );

      console.log("agentsResponce.data.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          subuserErrorMessage:
            "There is some error while sending invite to the user.",
          subuserSuccessMessage: "",
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState(
          {
            subuserErrorMessage: "",
            subuserSuccessMessage:
              "Invite successfully sent to the user. Page will be refreshed automatically.",
            disabled: false,
          },
          () => {
            setTimeout(() => {
              //window.$("#modalAddNewSubUser").modal("hide");
              this.handleCloseModal();
              this.setState({
                subuserErrorMessage: "",
                subuserSuccessMessage: "",
              });
              window.location.reload();
            }, 3000);
          }
        );
      } else {
        this.setState({
          subuserErrorMessage:
            "There is some error while sending invite to the user.",
          subuserSuccessMessage: "",
          disabled: false,
        });
      }
    }
  };

  handleCloseModal = () => {
    console.log("State before update:", this.state);
    this.setState({
      subuserErrorMessage: "",
      subuserSuccessMessage: "",
      subuser_name: "",
      subuser_email: "",
    });
    console.log("State after update:", this.state);
  };

  setDomain = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[5];

    if (domain_str === "active") {
      return (
        <span className="badge badge-outline-success">
          Active
          <em className="icon ni ni-check-round-cut" title="Verified"></em>
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          Inactive
          <em className="icon ni ni-alert-circle" title="Verified"></em>
        </span>
      );
    }
  };

  show_email = () => {
    this.setState({
      send_email: "1",
    });
  };

  fieldValidation = () => {
    let { comp_name, name, title, email, password, c_password, phone } =
      this.state;

    if (comp_name === "") {
      this.setState({
        errorCompName: true,
        errorName: false,
        errorTitle: false,
        errorPhone: false,
        errorEmail: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Company Name is required.",
      });
      return false;
    } else if (name === "") {
      this.setState({
        errorName: true,
        errorCompName: false,
        errorTitle: false,
        errorPhone: false,
        errorEmail: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Name is required.",
      });
      return false;
    } else if (title === "") {
      this.setState({
        errorTitle: true,
        errorCompName: false,
        errorPhone: false,
        errorName: false,
        errorEmail: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Title is required.",
      });
      return false;
    } else if (email === "") {
      this.setState({
        errorEmail: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorPhone: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Email is required.",
      });
      return false;
    } else if (!ValidateEmail(email)) {
      this.setState({
        errorEmail: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorPhone: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Invalid email format.",
      });
      return false;
    } else if (phone === "") {
      this.setState({
        errorPhone: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorEmail: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Phone is required.",
      });
      return false;
    } else if (!this.isPhoneValid(phone)) {
      this.setState({
        errorPhone: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorEmail: false,
        errorPass: false,
        errorcPass: false,
        errorMessageEdit: "Invalid phone number.",
      });
      return false;
    } else if (c_password !== "" && password === "") {
      this.setState({
        errorPass: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorPhone: false,
        errorEmail: false,
        errorcPass: false,
        errorMessageEdit: "Password is required.",
      });
      return false;
    } else if (password !== "" && c_password === "") {
      this.setState({
        errorcPass: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorPhone: false,
        errorEmail: false,
        errorPass: false,
        errorMessageEdit: "Confirm Password is required.",
      });
      return false;
    } else if (password !== c_password) {
      this.setState({
        errorPass: true,
        errorcPass: true,
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorPhone: false,
        errorEmail: false,
        errorMessageEdit: "Password and confirm password do not match.",
      });
      return false;
    } else {
      this.setState({
        errorCompName: false,
        errorName: false,
        errorTitle: false,
        errorEmail: false,
        errorPass: false,
        errorcPass: false,
        errorPhone: false,
        errorMessageEdit: "",
        disabled: true,
      });
      return true;
    }
  };

  formUpdateSubmit = async () => {
    let {
      name,
      email,
      password,
      phone,
      comp_name,
      title,
      user_id,
      account_status,
    } = this.state;

    if (this.fieldValidation() === true) {
      let apiResponce = await editSubUser(
        auth.getAccount(),
        auth.getToken(),
        name,
        email,
        password,
        phone,
        comp_name,
        title,
        user_id,
        account_status
      );
      console.log("Edit User RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "User updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(() => {
          window.location.href = "/sub_users";
        }, 2000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  resendActication = async (subuser_name, subuser_email) => {
    this.setState({
      subuserErrorMessage: "",
      subuserSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await sendSubUserInvite(
      auth.getAccount(),
      auth.getToken(),
      subuser_email,
      subuser_name
    );

    window.$("#modalresendActivation").modal("show");

    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        subuserErrorMessage: "Error sending activation email.",
        subuserSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState(
        {
          subuserErrorMessage: "",
          subuserSuccessMessage: "Activation email sent successfully.",
          disabled: false,
        },
        () => {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        subuserErrorMessage: "Error Sending Activation Email",
        subuserSuccessMessage: "",
        disabled: false,
      });
    }
  };

  modalHideResend = () => {
    window.$("#modalresendActivation").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Sub Users" icon="icon ni ni-user-list" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() => this.setNewSubUserSettings()}
                              className="btn btn-primary"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Sub User
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-edit"></em> Edit Sub User
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                id="comp_name"
                                name="comp_name"
                                type="text"
                                label="Company Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.comp_name}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorCompName}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="name"
                                name="name"
                                type="text"
                                label="Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.name}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorName}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="title"
                                name="title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorTitle}
                              />
                            </div>

                            <div className=" col-md-6">
                              {/* <MutextField
                                id="phone"
                                name="phone"
                                type="text"
                                inputProps={{ maxLength: 14 }}
                                label="Phone"
                                value={this.state.phone}
                                onChange={this.handleChange}
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                              /> */}
                              <PhoneInput
                                required
                                defaultCountry={"us"}
                                inputProps={{
                                  name: "phone",
                                  id: "phone",
                                  maxLength: 20,
                                }}
                                value={this.state.phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: {
                                      name: "phone",
                                      value,
                                    },
                                  })
                                }
                                inputStyle={{
                                  padding: "18px 8px",
                                }}
                                label="Phone Number"
                                error={this.state.errorPhone}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="email"
                                name="email"
                                type="email"
                                label="Email Address"
                                value={this.state.email}
                                onChange={this.handleChange}
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="account_status"
                                name="account_status"
                                select
                                label="Status"
                                value={this.state.account_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                <option key="status1" value="active">
                                  Active
                                </option>
                                <option key="status0" value="inactive">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="password"
                                name="password"
                                type={
                                  this.state.passwordShown ? "text" : "password"
                                }
                                label="Password"
                                value={this.state.password}
                                inputProps={{ maxLength: 100 }}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorPass}
                              />
                              <a
                                style={{
                                  cursor: "pointer",
                                  marginRight: "15px",
                                }}
                                tabIndex="-1"
                                onClick={() => {
                                  this.togglePassword();
                                }}
                                className="form-icon form-icon-right passcode-switch"
                                data-target="password"
                              >
                                {this.state.passwordShown ? (
                                  <em className="icon ni ni-eye-off"></em>
                                ) : (
                                  <em className="icon ni ni-eye"></em>
                                )}
                              </a>
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                id="c_password"
                                name="c_password"
                                type={
                                  this.state.confirmPasswordShown
                                    ? "text"
                                    : "password"
                                }
                                label="Confirm Password"
                                value={this.state.c_password}
                                inputProps={{ maxLength: 100 }}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                error={this.state.errorcPass}
                              />
                              <a
                                style={{
                                  cursor: "pointer",
                                  marginRight: "15px",
                                }}
                                tabIndex="-1"
                                onClick={() => {
                                  this.toggleConfirmPassword();
                                }}
                                className="form-icon form-icon-right passcode-switch"
                                data-target="password"
                              >
                                {this.state.confirmPasswordShown ? (
                                  <em className="icon ni ni-eye-off"></em>
                                ) : (
                                  <em className="icon ni ni-eye"></em>
                                )}
                              </a>
                            </div>
                            <div className="col-md-12">
                              <p>
                                NOTE: Password is optional. If you do not want
                                to change the password, leave the last two
                                fields empty.
                              </p>
                            </div>

                            <div style={{ clear: "both" }}></div>
                            <hr />
                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                {/* Table 5th Col Start */}

                <div className="nk-block nk-block-lg">
                  <div className="row g-gs">
                    <div className="col-xxl-12">
                      <div className="nk-block nk-block-lg">
                        <div className="card card-bordered">
                          <div className="card-inner">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#adminEmails"
                                >
                                  <em className="icon ni ni-user"></em>
                                  <span>Sub Users</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#agentEmails"
                                  //onClick={() => this.getAgentEmails()}
                                >
                                  <em className="icon ni ni-user"></em>
                                  <span>Invited Users</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="adminEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.columnServices}
                                    tableData={this.state.tableData}
                                    title="All Sub Users"
                                  />
                                )}
                              </div>
                              <div className="tab-pane" id="agentEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.columnServicesInvite}
                                    tableData={this.state.tableDataInvite}
                                    title="All Invited Users"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
                {/* New Sub User Modal Start */}
                <div
                  className="modal fade"
                  tabindex="-1"
                  id="modalAddNewSubUser"
                >
                  <div
                    className="modal-dialog modal-dialog-top modal-xl"
                    role="document"
                  >
                    <div className="modal-content">
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleCloseModal}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <em
                            className="icon ni ni-users"
                            style={{ marginRight: "15px" }}
                          ></em>{" "}
                          Add New Sub User
                        </h5>
                      </div>
                      <div className="modal-body">
                        {this.state.formLoader === true ? (
                          FormLoader()
                        ) : (
                          <div className="row g-4">
                            {this.state.subuserErrorMessage !== "" ? (
                              <div className="col-md-12 mb-3">
                                <div
                                  className="example-alert example-alert-revoke"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.subuserErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.subuserSuccessMessage !== "" ? (
                              <div className="col-md-12 mb-3">
                                <div
                                  className="example-alert example-alert-revoke"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.subuserSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="subuser_name"
                                    name="subuser_name"
                                    type="text"
                                    label="Full Name"
                                    value={this.state.subuser_name}
                                    inputProps={{ maxLength: 64 }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^a-zA-Z0-9 ]/gi,
                                        ""
                                      );
                                    }}
                                    helperText="Full name of the invite recipient."
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="subuser_email"
                                    name="subuser_email"
                                    type="text"
                                    label="Email Address"
                                    value={this.state.subuser_email}
                                    helperText="Email address of the invite recipient."
                                    inputProps={{ maxLength: 100 }}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                              <button
                                type="button"
                                onClick={() => this.saveNewSubUser()}
                                className="btn btn-primary"
                                disabled={this.state.disabled}
                              >
                                <span>Send Invite</span>{" "}
                                <em className="icon ni ni-emails"></em>
                              </button>
                            </div>
                            <div className="col-md-12">
                              <div className="example-alert">
                                <div className="alert alert-light">
                                  <ul className="list">
                                    <li>
                                      An email will be dispatched to the
                                      provided email address.
                                    </li>
                                    <li>
                                      This email will contain a secure,
                                      tokenized link for user registration.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* New Sub User Modal End */}
                {/* MODAL EDIT PROFILE START */}
                <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <a
                        style={{ cursor: "pointer" }}
                        className="close"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                      <div className="modal-body modal-body-lg text-center">
                        <div className="nk-modal">
                          <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                          <h4 className="nk-modal-title">
                            Delete Invited User?
                          </h4>
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="nk-modal-text">
                            <div className="caption-text">
                              Are you sure you want to delete this Invited User?
                            </div>
                          </div>
                          <div className="nk-modal-action">
                            <a
                              style={{ cursor: "pointer" }}
                              className="btn btn-lg btn-mw btn-primary"
                              onClick={() => {
                                this.modalHideDel();
                              }}
                            >
                              CANCEL
                            </a>
                            {"  "}
                            <a
                              style={{ cursor: "pointer" }}
                              className="btn btn-lg btn-mw btn-danger"
                              onClick={() => {
                                this.deleteInvitedUser();
                              }}
                            >
                              DELETE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Resend Activation Modal Start */}
                <div
                  className="modal fade"
                  tabIndex="-1"
                  id="modalresendActivation"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      {this.state.subuserSuccessMessage && (
                        <>
                          <a
                            style={{ cursor: "pointer" }}
                            className="close"
                            onClick={() => {
                              this.modalHideResend();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                              <h4
                                className="nk-modal-title"
                                style={{ fontSize: "1.3rem" }}
                              >
                                {this.state.subuserSuccessMessage}
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.subuserErrorMessage && (
                        <>
                          <a
                            style={{ cursor: "pointer" }}
                            className="close"
                            onClick={() => {
                              this.modalHideResend();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                              <h4
                                className="nk-modal-title"
                                style={{ fontSize: "1.3rem" }}
                              >
                                {this.state.subuserErrorMessage}
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* Resend Activation Modal Ends */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubUsers);
