import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getClientAgreement,
  editClientAgreement,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
} from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
//import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const auth = new HelperClass();

class Addagreement extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      agent_email: auth.getEmail(),
      agreement_title: "",
      agreement_content: "",
      agreement_instruction: "",
      agreement_type: "msa",
      agreement_type: "",
      agreement_id: "",
      is_default: 0,
      isactive: 1,
      issignup: 1,
      ismandatory: 1,
      ispublished: 0,
      tableData: [],
      isEditorReady: false,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    let agreement_id = this.props.props.match.params.id;
    console.log(agreement_id);
    const servicesResponce = await getClientAgreement(
      auth.getAccount(),
      auth.getToken(),
      agreement_id
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agreement_id: servicesResponce.data.data[0][5],
        agreement_title: servicesResponce.data.data[0][0],
        agreement_instruction: servicesResponce.data.data[0][3],
        isactive: servicesResponce.data.data[0][1],
        ismandatory: servicesResponce.data.data[0][2],
        agreement_content: servicesResponce.data.data[0][4],
        agreement_type: servicesResponce.data.data[0][6],
        tableLoader: false,
        isEditorReady: true,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  handleEditorChange = (event, editor) => {
    const content = editor.getData();
    const editEditorHtml = document.getElementById("editEditorHtml");
    editEditorHtml.value = content;
    this.setState({
      agreement_content: content,
    });
  };

  openEditorHtmlModal = () => {
    window.$("#modalEditorHtml").modal("show");
  };

  saveEditorHtml = () => {
    const pageContent = document.getElementById("editEditorHtml").value;
    this.setState(
      {
        agreement_content: pageContent,
        isEditorReady: false,
      },
      () => {
        if (this.editor && this.editor.ui.view.toolbar) {
          this.editor.ui.view.toolbar.element.remove();
        }
        this.editor = null;

        setTimeout(() => {
          this.setState({ isEditorReady: true });
          window.$("#modalEditorHtml").modal("hide");
        }, 500);
      }
    );
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const {
      agreement_title,
      agreement_instruction,
      isactive,
      ismandatory,
      agreement_content,
      agreement_type,
    } = this.state;

    var validated = true;

    if (agreement_title === "") {
      validated = false;
    } else if (agreement_instruction === "") {
      validated = false;
    } else if (agreement_content === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await editClientAgreement(
        auth.getAccount(),
        auth.getToken(),
        agreement_title,
        agreement_instruction,
        this.state.isactive.toString(),
        this.state.ismandatory.toString(),
        agreement_content,
        this.state.agreement_id,
        agreement_type
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "There is some error while updating the agreement.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Agreement updated successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/services/agreements");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalEditorHtml"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">Editor HTML</h5>
                        </div>
                        <div className="modal-body">
                          <textarea
                            className="prettyprint lang-html"
                            id="editEditorHtml"
                            defaultValue={this.state.agreement_content}
                          />
                        </div>
                        <div
                          className="modal-footer"
                          style={{ justifyContent: "right" }}
                        >
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              onClick={() => this.saveEditorHtml()}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/services")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Services
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/services/agreements")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Service Agreements
                  </Link>
                  <Typography color="textPrimary" className="">
                    Edit Service Agreement
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Update Agreement
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="agreement_title"
                                name="agreement_title"
                                type="text"
                                label="Agreement Title"
                                value={this.state.agreement_title}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="agreement_instruction"
                                name="agreement_instruction"
                                type="text"
                                label="Agreement Instructions"
                                value={this.state.agreement_instruction}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="isactive"
                                name="isactive"
                                select
                                label="Select Status"
                                value={this.state.isactive}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Agreement Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Active
                                </option>
                                <option key="0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="ismandatory"
                                name="ismandatory"
                                select
                                label="Mandatory"
                                value={this.state.ismandatory}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Agreement is mandatory"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Yes
                                </option>
                                <option key="0" value="0">
                                  No
                                </option>
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="agreement_type"
                                name="agreement_type"
                                select
                                label="Agreement Type"
                                value={this.state.agreement_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="E-Sign or Acceptance"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="esign" value="esign">
                                  E-Signature
                                </option>
                                <option key="accept" value="accept">
                                  Acceptance Only
                                </option>
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div
                            className="col-lg-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <h6 style={{ margin: 0, marginRight: "10px" }}>
                              Agreement Content
                            </h6>
                            {this.state.isEditorReady ? (
                              <>
                                <span
                                  className="badge badge-dim bg-outline-gray"
                                  style={{
                                    fontSize: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={this.openEditorHtmlModal}
                                >
                                  Source Code
                                  <em
                                    className="icon ni ni-code"
                                    style={{
                                      marginLeft: "5px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  ></em>
                                </span>
                              </>
                            ) : null}
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              {this.state.isEditorReady ? (
                                <>
                                  <CKEditor
                                    onReady={(editor) => {
                                      const editableElement =
                                        editor.ui.getEditableElement();
                                      if (
                                        editableElement &&
                                        editableElement.parentElement
                                      ) {
                                        editableElement.parentElement.insertBefore(
                                          editor.ui.view.toolbar.element,
                                          editableElement
                                        );
                                      }

                                      this.editor = editor;
                                    }}
                                    onError={(error, { willEditorRestart }) => {
                                      if (willEditorRestart) {
                                        if (
                                          this.editor &&
                                          this.editor.ui &&
                                          this.editor.ui.view &&
                                          this.editor.ui.view.toolbar
                                        ) {
                                          this.editor.ui.view.toolbar.element.remove();
                                        }
                                      }
                                    }}
                                    onChange={(event, editor) =>
                                      this.handleEditorChange(event, editor)
                                    }
                                    editor={DecoupledEditor}
                                    data={this.state.agreement_content}
                                    config={{
                                      cloudServices: {
                                        tokenUrl: fetchToken,
                                        uploadUrl:
                                          AGENT_SERVER_URL +
                                          "agents/editor_image_upload",
                                      },
                                      toolbar: {
                                        items: [
                                          "undo",
                                          "redo",
                                          "|",
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strikethrough",
                                          "|",
                                          "fontFamily",
                                          "fontSize",
                                          "fontColor",
                                          "fontBackgroundColor",
                                          "|",
                                          "alignment",
                                          "indent",
                                          "outdent",
                                          "|",
                                          "numberedList",
                                          "bulletedList",
                                          "|",
                                          "link",
                                          "imageUpload",
                                          "insertTable",
                                          "|",
                                          "blockQuote",
                                        ],
                                      },
                                      language: "en",
                                    }}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">Client Place Holders</th>
                                  <td>
                                    <ul className="preview-list">
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_SIGNOR_TITLE]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_SIGNOR_NAME]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_COMPANY_NAME]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_EMAIL]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_ADDRESS]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CONTRACT_DATE]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_SIGN]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[CLIENT_499_FILER_ID]]
                                        </span>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">User Place Holders</th>
                                  <td>
                                    <ul className="preview-list">
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[VENDOR_SIGNOR_TITLE]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[VENDOR_SIGNOR_NAME]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[VENDOR_SIGNOR_ADDRESS]]
                                        </span>
                                      </li>

                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[VENDOR_SIGNOR_SIGN]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[VENDOR_SIGNOR_COMPANY_NAME]]
                                        </span>
                                      </li>
                                      <li className="preview-item">
                                        <span className="badge badge-outline-gray">
                                          [[VENDOR_CONTRACT_DATE]]
                                        </span>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addagreement);
